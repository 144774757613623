import { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Alert, TextField, Typography, Link } from '@mui/material';
import { FirebaseContext } from '@/contexts/firebase-context';
import { doc, setDoc, serverTimestamp, collection } from 'firebase/firestore';

type PropsParam = {
  title: string;
};

export function ContactView(props: PropsParam) {
  const {
    state: { auth, db },
  } = useContext(FirebaseContext);
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [message, setMessage] = useState('');

  function handleFormSubmit() {
    if (message) {
      setDoc(doc(collection(db, 'customer_requests')), {
        createdDate: serverTimestamp(),
        customerID: auth.currentUser!.uid,
        body: message,
      })
        .then(() => {
          setSuccessAlert(true);
          setMessage('');
        })
        .catch(console.error);
    }
  }

  useEffect(() => {
    document.title = props.title;
  }, []);

  return (
    <Box display='flex'>
      <Container maxWidth='lg'>
        <Box paddingY='36px'>
          <Typography variant='h4' align='center' gutterBottom>
            Contact CVRE
          </Typography>
        </Box>
        <Box sx={{ pt: 0 }}>
          {showSuccessAlert && (
            <Alert variant='outlined' severity='success'>
              Contact request submitted successfully!
            </Alert>
          )}
          <Box marginY='2rem'>
            <TextField
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              rows={5}
              label='Message'
              placeholder='Enter message here'
              autoComplete='off'
              multiline
              autoFocus
              required
              fullWidth
            />
            <Box marginY='1rem'>
              <Button
                type='submit'
                variant='contained'
                onClick={handleFormSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
          <Typography variant='h5' align='center'>
            <Link
              style={{ textDecoration: 'none', userSelect: 'text' }}
              href='mailto:info@cvre.ca'
            >
              Email: info@cvre.ca
            </Link>
          </Typography>
          <Typography variant='h5' align='center'>
            <Link
              style={{ textDecoration: 'none', userSelect: 'text' }}
              href='callto:4165494915'
            >
              Telephone: (416) 549 - 4915
            </Link>
          </Typography>
          <Typography variant='h5' align='center'>
            <Link
              style={{ textDecoration: 'none', userSelect: 'text' }}
              href='callto:18554865597'
            >
              Toll Free: 1 (855) - 486 - 5597
            </Link>
          </Typography>
          <Typography variant='h5' align='center'>
            <Link
              style={{ textDecoration: 'none', userSelect: 'text' }}
              href='callto:4165494916'
            >
              Fax: (416) 549 - 4916
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
