import { getDoc, doc } from "firebase/firestore";
import { Staff } from "../models/staff";
import { db } from "./firebase-service";
import { findImageAvatarByPath } from "./image-service";



export async function findStaffById(staffId: string) {
  const snapshot = await getDoc(doc(db, "staff", staffId));
  const data = snapshot.data();
  const imageURL = await findImageAvatarByPath('staff_avatars/' + snapshot.id)
  return ({id: snapshot.id, ...data, imageURL}) as Staff;
}