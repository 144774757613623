import {
	getDocs,
	query,
	collection,
	where,
	limit,
	startAfter,
	orderBy,
	DocumentData,
	QueryDocumentSnapshot,
	documentId,
} from "firebase/firestore";
import { CustomerGroup, SuiteGroup } from "@/data/models";
import { db, findById, findImagePlaceholderByPath } from "@/data/services";

async function parserSuiteGroup(document: QueryDocumentSnapshot<DocumentData>) {
	return {
		id: document.id,
		...document.data(),
		imageURL: (await findImagePlaceholderByPath(document.data().filePaths?.[0])) as string,
		createdDate: document.data().createdDate?.toDate(),
		updatedDate: document.data().updatedDate?.toDate(),
	} as SuiteGroup;
}

async function parserSuiteGroupNoImage(document: QueryDocumentSnapshot<DocumentData>) {
	return {
		id: document.id,
		...document.data(),
		createdDate: document.data().createdDate?.toDate(),
		updatedDate: document.data().updatedDate?.toDate(),
	};
}

export async function findSuiteGroupById(suiteGroupId: string) {
	const suiteGroupResponse = await findById(suiteGroupId, "suite_groups");

	const parserData = {
		...suiteGroupResponse,
		createdDate: suiteGroupResponse.createdDate?.toDate(),
		updatedDate: suiteGroupResponse.updatedDate?.toDate(),
	};
	return parserData as SuiteGroup;
}

export async function findAllSuiteGroups(lastVisible: any, limitDoc: number = 12) {
	const suiteGroupsRef = collection(db, "suite_groups");
	const queryOptions = [where("active", "==", true), orderBy("updatedDate"), limit(limitDoc)];
	if (lastVisible) {
		//@ts-ignore
		queryOptions.push(startAfter(lastVisible));
	}
	const queryRef = query(suiteGroupsRef, ...queryOptions);
	const querySnapshot = await getDocs(queryRef);
	const suiteGroups = await Promise.all(querySnapshot.docs.map((doc) => parserSuiteGroup(doc)));
	const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1] || null;
	return {
		suiteGroups: suiteGroups as Array<SuiteGroup>,
		lastVisible: newLastVisible as QueryDocumentSnapshot<DocumentData>,
	};
}

export async function findAllSuiteGroupsByCustomerGroup(customerGroups: Array<CustomerGroup>) {
	const suiteGroupsRef = collection(db, "suite_groups");
	const parseCustomerGroupData = customerGroups.map((customerGroup) => ({
		label: customerGroup.groupName,
		value: customerGroup.id,
	}));
	const queryOptions = [
		where("active", "==", true),
		where("customerGroup", "array-contains-any", parseCustomerGroupData),
		orderBy("updatedDate"),
	];
	const queryRef = query(suiteGroupsRef, ...queryOptions);
	const querySnapshot = await getDocs(queryRef);
	const suiteGroups = await Promise.all(querySnapshot.docs.map((doc) => parserSuiteGroup(doc)));
	return {
		suiteGroups: suiteGroups as Array<SuiteGroup>,
	};
}

export async function findAllSuiteGroupsById(suiteGroupsId: Array<string>) {
	const snapshot = await getDocs(query(collection(db, "suite_groups"), where(documentId(), "in", suiteGroupsId)));
	const suiteGroups = await Promise.all(snapshot.docs.map(parserSuiteGroup));
	return suiteGroups as Array<SuiteGroup>;
}

export async function findAllSuiteGroupsByBuildingIdAndActive(buildingsId: Array<string>, active: boolean) {
	if (!buildingsId.length) return [];

	const snapshot = await getDocs(
		query(collection(db, "suite_groups"), where("buildingID", "in", buildingsId), orderBy("updatedDate", "desc"))
	);
	const suiteGroups = await Promise.all(snapshot.docs.map(parserSuiteGroup));
	return suiteGroups.filter((suiteGroup) => suiteGroup.active === active) as Array<SuiteGroup>;
}
