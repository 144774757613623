import { Dialog, DialogTitle, DialogContent, Box, Button, DialogActions, DialogContentText } from "@mui/material"
import { Link } from "react-router-dom"

type PropsParam = {
  open: boolean
  onClose: Function
}

export function LoginRegisterModal(props: PropsParam) {
  return (
    <Dialog open={props.open} onClose={() => props.onClose()} >
      <DialogTitle textAlign="center">
        Access Restricted
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center">
          Only registered users can make enquiries.<br /> Please login or register to continue.
        </DialogContentText>
        <Box display="flex" flexDirection="column" flex="1" gap="20px" marginTop="40px">
          <Button 
            variant="contained" 
            component={Link}
            to="/signin"
            fullWidth
          >
            Login
          </Button>
          <Button 
            variant="contained" 
            component={Link}
            to="/signup"
            fullWidth
          >
            Register
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>CANCEL</Button>
      </DialogActions>
    </Dialog>
  )
}
