import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconButton, Typography, Card, CardContent, CardMedia, Box, Button, CardActions, CircularProgress, Container, Grid } from "@mui/material";
import { Favorite, BathroomOutlined, BedroomParentOutlined, ArrowBackIosNew  } from "@mui/icons-material";
import { ObjectKey, SuiteGroup } from "@/data/models";
import { FirebaseContext } from "@/contexts/firebase-context";
import { findBuilderById, findBuildingById, updateWatchedListingsCode, findAllWatchedListingsCode, findAllSuiteGroupsByBuildingIdAndActive, findAllBuilding } from "@/data/services";

type PropsParam = {
  title: string
  current: boolean
}

export function ProjectsView(props: PropsParam) {
  const navigate = useNavigate();
  const params = useParams<ObjectKey>();
  const {state: { user }} = useContext(FirebaseContext);
  const [suiteGroups, setSuiteGroups] = useState<Array<SuiteGroup>>([]);
  const [showLoading, setLoading] = useState<boolean>(true);
  const [watchList, setWatchList] = useState<Array<string>>([]);
  const [updateWatchList, setUpdateWatchList] = useState<number>(0);
  const [firstLoaderPage, setFirstLoaderPage] = useState(true);
  const [buildingHashMap, setBuildingHashMap] = useState<any>({});

  async function handleFindAllSuiteGroups() {
    const builderResponse = await findBuilderById(params.id);
    const buildingResponse = await Promise.all(
      builderResponse.buildings.map(building => findBuildingById(building.value)
    ));
    const buildingIdList = buildingResponse.map(building => building.id as string);
    const response = await findAllSuiteGroupsByBuildingIdAndActive(buildingIdList, props.current);
    setSuiteGroups(response);
    setLoading(false);
  }

  async function handleAddToWatchList(groupId: string, index: number) {
    if (watchList?.includes(groupId)) {
      updateWatchedListingsCode(user?.uid!, groupId, true);
      delete watchList[index]
      setWatchList(watchList)
    } else {
      updateWatchedListingsCode(user?.uid!, groupId, false)
      setWatchList([...watchList, groupId])
    }
    setUpdateWatchList(old => old + 1)
  }

  async function handleBuildingHashMap() {
    const buildings = await findAllBuilding();
    const createBuildingHashMap: any = {};
    for (const building of buildings) {
      createBuildingHashMap[building.id!] = building;
    }
    setBuildingHashMap(createBuildingHashMap);
  }
  
  useEffect(() => {
    if (firstLoaderPage) {
      document.title = props.title;
      setFirstLoaderPage(false);
      handleFindAllSuiteGroups();
      handleBuildingHashMap();
    }

    if (user) {
      findAllWatchedListingsCode(user.uid).then(response => setWatchList(response));
    }
  }, [user, updateWatchList]);

  return (
    <Box display="flex">
      <Container maxWidth="lg">
        <Box margin="10px 0 30px 0">
          <Button startIcon={<ArrowBackIosNew />} onClick={() => navigate(-1)}>Back</Button>
        </Box>
        <Box paddingY="36px">
          <Typography variant="h4" align="center" gutterBottom>
            Your dream property awaits
          </Typography>
        </Box>
        {showLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <CircularProgress />
          </Box>
        )}
        {!showLoading && !suiteGroups.length && (
          <Typography align="center">No suites found...</Typography>
        )}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {suiteGroups &&
            suiteGroups.map((suiteGroup, index) => (
              <Grid key={suiteGroup.id} xs={12} sm={4} md={3} item>
                <Card sx={{ minHeight: "400px", display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    width="100%"
                    height="200"
                    image={suiteGroup.imageURL}
                  />
                  <CardContent>
                    <Typography 
                      gutterBottom
                      variant="h6"
                      component="div"
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                    >
                      {suiteGroup?.suiteGroupType ?? 'n/a'}
                    </Typography>
                    <Typography gutterBottom variant="body2" color="text.secondary">
                      {`${buildingHashMap[suiteGroup?.buildingID]?.streetAddress ?? 'n/a'}, ${buildingHashMap[suiteGroup?.buildingID]?.city ?? ''}`}
                    </Typography>
                    {!!suiteGroup?.balconySqFt 
                      ? (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          textOverflow={'ellipsis'}
                          overflow={'hidden'}
                          whiteSpace={'nowrap'}
                        >
                          {`Indoor ${suiteGroup.balconySqFt} sq. ft. / Outdoor ${Number(suiteGroup?.totalSqFt) - Number(suiteGroup.balconySqFt) ?? 'n/a'} sq. ft.`}
                        </Typography>
                        ) 
                      : null}
                    <Typography gutterBottom variant="body2" color="text.secondary">
                      {`Total ${suiteGroup?.totalSqFt || 'n/a'} sq. ft.`}
                    </Typography>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                      <BedroomParentOutlined />
                      <Typography variant="body2" color="text.secondary">
                        {suiteGroup?.bedroomCount ?? 'n/a'} Bedrooms
                      </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                      <BathroomOutlined /> 
                      <Typography variant="body2" color="text.secondary">
                      {suiteGroup?.bathroomCount ?? 'n/a'} Bathrooms
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions style={{paddingLeft: user ? '0' : '16px', marginTop: 'auto', marginBottom: 16}}>
                    {user && (
                      <IconButton
                        sx={{ color: watchList?.includes(suiteGroup.id) ? "red" : "primary" }}
                        onClick={() => handleAddToWatchList(suiteGroup.id, index)}
                      >
                        <Favorite />
                      </IconButton>
                    )}
                    <Button
                      component={Link}
                      to={"/suites/" + suiteGroup.id}
                      size="small"
                      color="primary"
                      variant="contained"
                    >
                      View
                    </Button>
                    </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
};
