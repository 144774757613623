import React, { useState, useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import { Row, Col, Container } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Card, CardContent } from "@mui/material";
import "./now-ui-kit.css";
import { findAllBuilding } from "@/data/services";
import { int } from "babylonjs";
import { CustomerGroup } from "@/data/models";
interface OptionType {
	value: string;
	label: string;
}

const containerStyle = {
	display: "flex",
	flexDirection: "row",
	gap: "10px",
	flexWrap: "wrap",
	marginTop: "20px",
};

const customStyles: StylesConfig<OptionType, false> = {
	control: (provided, state) => ({
		...provided,
		width: "300px",
		borderColor: state.isFocused ? "#E8E8E8" : provided.borderColor,
		boxShadow: state.isFocused ? "0 0 0 1px #E8E8E8" : provided.boxShadow,
		boxSizing: "border-box",
		backgroundColor: "#000",
		color: "#black",
		cursor: "pointer",
	}),
	singleValue: (provided) => ({
		...provided,
		color: "fff",
	}),
	menu: (provided) => ({
		...provided,
		borderRadius: "10px",
		width: "300px",
		backgroundColor: "#E8E8E8",
		color: "black",
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused
			? "rgba(44, 168, 255, 0.1)"
			: state.isSelected
			? "#E8E8E8"
			: provided.backgroundColor,
		color: state.isSelected ? "#080808" : provided.color,
		cursor: "pointer",
	}),
};

function FilterBar({ customerGroups }: { customerGroups: CustomerGroup[] }) {
	const navigate = useNavigate();
	const location = useLocation();

	// Data
	const [chooseBuilding, setChooseBuilding] = useState<OptionType | null>(null);
	const [chooseCity, setChooseCity] = useState<OptionType | null>(null);
	const [priceRange, setPriceRange] = useState<OptionType | null>(null);
	const [bedrooms, setBedrooms] = useState<(OptionType | null)[]>([]);
	const [bathrooms, setBathrooms] = useState<(OptionType | null)[]>([]);

	// UI
	const [buildingOptions, setBuildingOptions] = useState<OptionType[]>([]);
	const [cityOptions, setCityOptions] = useState<OptionType[]>([]);
	const [selectedBedrooms, setSelectedBedrooms] = useState<boolean[]>([false, false, false, false, false]);
	const [selectedBathrooms, setSelectedBathrooms] = useState<boolean[]>([false, false, false]);

	useEffect(() => {
		let cityOptions: OptionType[];
		let buildingOptions: OptionType[];

		/**
		 * Asynchronous function to fetch options for buildings and cities.
		 */
		const initialize = async () => {
			try {
				console.log(customerGroups);
				// Fetch the list of all buildings.
				const buildingList = await findAllBuilding();
				console.log(buildingList);
				// Extract unique building names, using "unknown" for buildings without a name.
				const uniqueBuildings = [
					...new Set(
						buildingList.map((building) => {
							if (!building.buildingName) return "Unknown";
							return building.buildingName;
						})
					),
				];

				// Sort and map unique building names to set building options.
				buildingOptions = uniqueBuildings
					.filter((buildingName): buildingName is string => buildingName !== undefined)
					.sort((a, b) => a.localeCompare(b))
					.map((buildingName) => ({
						value: buildingName,
						label: buildingName,
					}));

				setBuildingOptions(buildingOptions);

				// Extract unique cities, using "unknown" for buildings without a city.
				const uniqueCities = [
					...new Set(
						buildingList.map((building) => {
							if (!building.city) {
								return "Unknown";
							}
							return building.city;
						})
					),
				];

				cityOptions = uniqueCities
					.sort((a, b) => a.localeCompare(b))
					.map((city) => ({
						value: city,
						label: city,
					}));

				// Sort and map unique city names to set city options.
				setCityOptions(cityOptions);
			} catch (error) {
				// Log an error message if fetching options fails.
				console.error("Error fetching options:", error);
			}

			// Load is complete, now we read an assign current params.
			const query = new URLSearchParams(location.search);
			const buildingNameParam = query.get("building_name");
			const cityParam = query.get("city");
			const priceRangeParam = query.get("price_range");
			const bedroomsParam = query.get("bedrooms");
			const bathroomsParam = query.get("bathrooms");

			//Set building
			// Get the building name from the query parameters and set the single select option.
			if (buildingNameParam) {
				setChooseBuilding(
					buildingOptions.find((option) => option.value.toLowerCase() === buildingNameParam.toLowerCase()) ||
						null
				);
			}

			// Get the city from the query parameters and set the chosen city option.
			if (cityParam) {
				setChooseCity(
					cityOptions.find((option) => {
						return option.value.toLowerCase() === cityParam.toLowerCase();
					}) || null
				);
			}

			// Get the price range from the query parameters and set the price range option.
			if (priceRangeParam) {
				setPriceRange(singleOptionPriceRange.find((option) => option.value === priceRangeParam) || null);
			}

			//Set Bedrooms
			if (bedroomsParam) {
				const bedroomSelection = multipleOptionBedroom.map((option) => bedroomsParam.includes(option.value));
				setSelectedBedrooms(bedroomSelection);
			}

			//Set Bathrooms
			if (bathroomsParam) {
				const bathroomSelection = multipleOptionBathroom.map((option) => bathroomsParam.includes(option.value));
				setSelectedBathrooms(bathroomSelection);
			}
		};

		/**
		 * Synchronous function to fetch options for buildings and cities.
		 */

		// Call fetchOptions function to fetch data once after initial render.
		initialize();
	}, []); // Empty dependency array ensures this effect runs only once.

	const singleOptionPriceRange = [
		{ value: "300000-400000", label: "300,000 - 400,000" },
		{ value: "400000-500000", label: "400,000 - 500,000" },
		{ value: "500000-600000", label: "500,000 - 600,000" },
		{ value: "600000-700000", label: "600,000 - 700,000" },
		{ value: "700000-or-more", label: "700,000 or more" },
	];

	const multipleOptionBedroom: OptionType[] = [
		{ value: "1", label: "1 Bedroom" },
		{ value: "1+D", label: "1 Bedroom + Den" },
		{ value: "2", label: "2 Bedrooms" },
		{ value: "2+D", label: "2 Bedrooms + Den" },
		{ value: "3+", label: "3 Bedrooms +" },
	];

	const multipleOptionBathroom: OptionType[] = [
		{ value: "1", label: "1 Bathroom" },
		{ value: "2", label: "2 Bathrooms" },
		{ value: "3+", label: "3 Bathrooms" },
	];

	const handleBedroomChipToggle = (i: int) => {
		setSelectedBedrooms((prevSelectedBedrooms) => {
			const updatedSelectedBedrooms = [...prevSelectedBedrooms];
			updatedSelectedBedrooms[i] = !updatedSelectedBedrooms[i];

			if (updatedSelectedBedrooms[i]) {
				let prevBedrooms = bedrooms;
				const updatedBedrooms = [...prevBedrooms];
				updatedBedrooms[i] = multipleOptionBedroom[i];
				setBedrooms(updatedBedrooms);
			} else {
				let prevBedrooms = bedrooms;
				const updatedBedrooms = [...prevBedrooms];
				updatedBedrooms[i] = null;
				setBedrooms(updatedBedrooms);
			}

			return updatedSelectedBedrooms;
		});
	};

	const handleBathChipToggle = (i: int) => {
		setSelectedBathrooms((prevSelectedBathrooms) => {
			const updatedSelectedBathrooms = [...prevSelectedBathrooms];
			updatedSelectedBathrooms[i] = !updatedSelectedBathrooms[i];

			if (updatedSelectedBathrooms[i]) {
				let prevBathrooms = bathrooms;
				const updatedBathrooms = [...prevBathrooms];
				updatedBathrooms[i] = multipleOptionBathroom[i];
				setBathrooms(updatedBathrooms);
			} else {
				let prevBathrooms = bathrooms;
				const updatedBathrooms = [...prevBathrooms];
				updatedBathrooms[i] = null;
				setBathrooms(updatedBathrooms);
			}

			return updatedSelectedBathrooms;
		});
	};

	const handleReset = () => {
		setChooseBuilding(null);
		setChooseCity(null);
		setPriceRange(null);
		setBedrooms([]);
		setBathrooms([]);
		setSelectedBedrooms([false, false, false, false, false]);
		setSelectedBathrooms([false, false, false]);

		// Clear URL parameters
		navigate({ search: "" });
	};

	const updateQueryString = () => {
		const params = new URLSearchParams();

		if (chooseBuilding) {
			params.set("building_name", chooseBuilding.value);
		}

		if (chooseCity) {
			params.set("city", chooseCity.value);
		}

		if (priceRange) {
			params.set("price_range", priceRange.value);
		}

		if (bedrooms.length > 0) {
			var tempArr: string[] = [];
			bedrooms.forEach((b) => {
				if (b != null && b != undefined) {
					tempArr.push(b.value);
				}
			});
			params.set("bedrooms", tempArr.join(","));
		}

		if (bathrooms.length > 0) {
			var tempArr: string[] = [];
			bathrooms.forEach((b) => {
				if (b != null && b != undefined) {
					tempArr.push(b.value);
				}
			});
			params.set("bathrooms", tempArr.join(","));
		}

		// console.log("Updated Query Params:", params.toString());
		navigate({ search: params.toString() });
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				marginTop: "50px",
			}}>
			<h1>Choose your Property</h1>
			<Card
				sx={{
					backgroundColor: "#020202",
					color: "#ffffff",
					padding: "20px",
					margin: "50px",
					borderRadius: "20px",
				}}>
				<CardContent>
					<Container>
						<Row style={containerStyle}>
							<Col lg="4" md="6" sm="12" style={{ flex: "1 1 0px" }}>
								<h4>Development</h4>
								<Select
									styles={customStyles}
									onChange={(value) => {
										setChooseBuilding(value);
									}}
									placeholder="Choose Development"
									value={chooseBuilding}
									options={buildingOptions}
								/>
							</Col>
							<Col lg="4" md="6" sm="12" style={{ flex: "1 1 0px" }}>
								<h4>City</h4>
								<Select
									styles={customStyles}
									onChange={(value) => {
										setChooseCity(value);
									}}
									placeholder="Choose City"
									value={chooseCity}
									options={cityOptions}
								/>
							</Col>
							<Col lg="4" md="6" sm="12" style={{ flex: "1 1 0px" }}>
								<h4>Price</h4>
								<Select
									styles={customStyles}
									onChange={(value) => {
										setPriceRange(value);
									}}
									placeholder="Set Price Range"
									value={priceRange}
									options={singleOptionPriceRange}
								/>
							</Col>
						</Row>
						<Row style={containerStyle}>
							<Col sm="12" style={{ marginTop: "10px", marginRight: "85px" }}>
								<h4>Bedrooms</h4>
								<Stack
									direction={{ xs: "column", md: "row" }}
									spacing={1}
									sx={{
										flexWrap: "wrap",
										gap: 1,
										justifyContent: { xs: "flex-start", md: "flex-start" },
										alignItems: { xs: "flex-start", md: "center" },
									}}>
									{multipleOptionBedroom.map((option, i) => {
										return (
											<Chip
												key={option.value}
												label={option.label}
												clickable
												onClick={() => handleBedroomChipToggle(i)}
												variant={selectedBedrooms[i] ? "filled" : "outlined"}
												sx={{
													margin: "5px",
													backgroundColor: selectedBedrooms[i] ? "#E8E8E8" : "transparent",
													color: selectedBedrooms[i] ? "black" : "white",
													borderColor: selectedBedrooms[i] ? "#E8E8E8" : "grey",
													"&:hover": {
														borderColor: "#E8E8E8",
														backgroundColor: selectedBedrooms[i]
															? "#E8E8E8"
															: "rgba(44, 168, 255, 0.1)",
													},
												}}
											/>
										);
									})}
								</Stack>
							</Col>
							<Col sm="12" style={{ marginTop: "10px" }}>
								<h4>Bathrooms</h4>
								<Stack
									direction={{ xs: "column", md: "row" }}
									spacing={1}
									sx={{
										flexWrap: "wrap",
										gap: 1,
										justifyContent: { xs: "flex-start", md: "flex-start" },
										alignItems: { xs: "flex-start", md: "center" },
									}}>
									{multipleOptionBathroom.map((option, i) => {
										return (
											<Chip
												key={option.value}
												label={option.label}
												clickable
												onClick={() => handleBathChipToggle(i)}
												variant={selectedBathrooms[i] ? "filled" : "outlined"}
												sx={{
													margin: "5px",
													backgroundColor: selectedBathrooms[i] ? "#E8E8E8" : "transparent",
													color: selectedBathrooms[i] ? "black" : "white",
													borderColor: selectedBathrooms[i] ? "#E8E8E8" : "grey",
													"&:hover": {
														borderColor: "#E8E8E8",
														backgroundColor: selectedBathrooms[i]
															? "#E8E8E8"
															: "rgba(44, 168, 255, 0.1)",
													},
												}}
											/>
										);
									})}
								</Stack>
							</Col>
						</Row>
						<div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "20px" }}>
							<div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
								<button
									onClick={updateQueryString}
									className="search-button"
									style={{
										padding: "10px 20px",
										fontSize: "16px",
										backgroundColor: "#E8E8E8",
										color: "black",
										border: "none",
										borderRadius: "10px",
										cursor: "pointer",
										flex: 6,
										marginRight: "10px",
									}}>
									Search
								</button>
								<button
									onClick={handleReset}
									className="reset-button"
									style={{
										padding: "10px 20px",
										fontSize: "16px",
										backgroundColor: "#ffffff",
										color: "#black",
										border: "2px solid #E8E8E8",
										borderRadius: "10px",
										cursor: "pointer",
										flex: 1,
									}}>
									Reset
								</button>
							</div>
						</div>
					</Container>
				</CardContent>
			</Card>
		</div>
	);
}

export default FilterBar;
