import { useEffect } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { ArrowBackIosNew } from "@mui/icons-material";
import { TableBody, TableRow, TableCell, TableHead, Table, Link} from "@mui/material";
import { Box, Button, Container, Grid, Typography, TableContainer} from "@mui/material";

type PropsParam = {
  title: string
}

export function BuilderView(props: PropsParam) {
  const params = useParams()
  const navigate = useNavigate();
  const builder = useLoaderData() as any

  useEffect(() => {
    document.title = props.title
  }, []);

  return (
    <Box display="flex">
      {builder && (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box>
          <Button startIcon={<ArrowBackIosNew />} onClick={() => navigate(-1)}>Back</Button>
        </Box>
          <Box style={{ textAlign: "center" }}>
            <Typography
              sx={
                {
                  // display: { xs: "block", sm: "none", md: "none", lg: "none" },
                }
              }
              variant="h5"
              align="center"
              gutterBottom
            >
              {builder.builderName}
            </Typography>
            {builder.contact && (
              <Link href={builder.contact.builderLink}>
                {builder.contact.builderLink}
              </Link>
            )}
            {builder.contact && (
              <Box sx={{ pt: 4, pb: 4 }}>
                {/* <Typography variant="h6" align="left" sx={{ mt: 5 }} gutterBottom>
                                    Builder Contact Info
                                </Typography> */}

                <Grid container spacing={0} maxWidth="lg">
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="right">Name</TableCell>
                          <TableCell align="right">Email</TableCell>
                          <TableCell align="right">Phone</TableCell>
                          <TableCell align="right">Address</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {builder.contact && (
                          <TableRow>
                            <TableCell>Builder Representative</TableCell>
                            <TableCell>
                              {builder.contact.name && builder.contact.name}
                            </TableCell>
                            <TableCell align="right">
                              {builder.contact.email && builder.contact.email}
                            </TableCell>
                            <TableCell align="right">
                              {builder.contact.phone && builder.contact.phone}
                            </TableCell>
                            <TableCell align="right">
                              {builder.contact.address &&
                                builder.contact.address}
                            </TableCell>
                          </TableRow>
                        )}
                        {builder.representative && (
                          <TableRow>
                            <TableCell>Representative</TableCell>
                            <TableCell>
                              {builder.representative.name &&
                                builder.representative.name}
                            </TableCell>
                            <TableCell align="right">
                              {builder.representative.email &&
                                builder.representative.email}
                            </TableCell>
                            <TableCell align="right">
                              {builder.representative.phone &&
                                builder.representative.phone}
                            </TableCell>
                            <TableCell align="right">
                              {builder.representative.address &&
                                builder.representative.address}
                            </TableCell>
                          </TableRow>
                        )}
                        {builder.management && (
                          <TableRow>
                            <TableCell>Property Management</TableCell>
                            <TableCell>
                              {builder.management.name &&
                                builder.management.name}
                            </TableCell>
                            <TableCell align="right">
                              {builder.management.email &&
                                builder.management.email}
                            </TableCell>
                            <TableCell align="right">
                              {builder.management.phone &&
                                builder.management.phone}
                            </TableCell>
                            <TableCell align="right">
                              {builder.management.address &&
                                builder.management.address}
                            </TableCell>
                          </TableRow>
                        )}
                        {builder.management && (
                          <TableRow>
                            <TableCell>Builder Contact</TableCell>
                            <TableCell>
                              {builder.management.name &&
                                builder.management.name}
                            </TableCell>
                            <TableCell align="right">
                              {builder.management.email &&
                                builder.management.email}
                            </TableCell>
                            <TableCell align="right">
                              {builder.management.phone &&
                                builder.management.phone}
                            </TableCell>
                            <TableCell align="right">
                              {builder.management.address &&
                                builder.management.address}
                            </TableCell>
                          </TableRow>
                        )}
                        {builder.closing && (
                          <TableRow>
                            <TableCell>Closing</TableCell>
                            <TableCell>
                              {builder.closing.name && builder.closing.name}
                            </TableCell>
                            <TableCell align="right">
                              {builder.closing.email && builder.closing.email}
                            </TableCell>
                            <TableCell align="right">
                              {builder.closing.phone && builder.closing.phone}
                            </TableCell>
                            <TableCell align="right">
                              {builder.closing.address &&
                                builder.closing.address}
                            </TableCell>
                          </TableRow>
                        )}
                        {builder.leasing && (
                          <TableRow>
                            <TableCell>Leasing</TableCell>
                            <TableCell>
                              {builder.leasing.name && builder.leasing.name}
                            </TableCell>
                            <TableCell align="right">
                              {builder.leasing.email && builder.leasing.email}
                            </TableCell>
                            <TableCell align="right">
                              {builder.leasing.phone && builder.leasing.phone}
                            </TableCell>
                            <TableCell align="right">
                              {builder.leasing.address &&
                                builder.leasing.address}
                            </TableCell>
                          </TableRow>
                        )}
                        {builder.pdi && (
                          <TableRow>
                            <TableCell>Leasing</TableCell>
                            <TableCell>
                              {builder.pdi.name && builder.pdi.name}
                            </TableCell>
                            <TableCell align="right">
                              {builder.pdi.email && builder.pdi.email}
                            </TableCell>
                            <TableCell align="right">
                              {builder.pdi.phone && builder.pdi.phone}
                            </TableCell>
                            <TableCell align="right">
                              {builder.pdi.address && builder.pdi.address}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
            )}
            <Button
              type="button"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 1 }}
              onClick={() => navigate('/builders/'+ params.id +'/previous-projects')}
            >
              Previous Projects
            </Button>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              sx={{ mt: 2, mb: 2 }}
              onClick={() =>  navigate('/builders/'+ params.id +'/current-projects')}
            >
              Current Projects
            </Button>
          </Box>
        </Container>
      )}
    </Box>
  );
};
