import { findById } from "../services/firebase-service";

type LoaderProps = {
  params: any
}

export async function builderLoader({ params }: LoaderProps) {
  const document = await findById(params.id, "builders");
  if (document) {
    return document
  }
  throw new Error("builder not found!")
}