import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../services/firebase-service";

type LoaderProps = {
  params: any
}

export async function enquiredPropertiesLoader({ params }: LoaderProps) {
  const userId = localStorage.getItem("userID")
  const snapshot = await getDocs(query(collection(db, "journeys"), where("customerID", "==", userId), where("active", "==", true)))
  const journeys = [] as Array<{ id: string, displayName: string}>
  snapshot.forEach((doc) => {
    journeys.push({
      id: doc.id,
      displayName: doc.data().displayName 
    })
  })
  return journeys
}