import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	IconButton,
	Typography,
	Card,
	CardContent,
	CardMedia,
	Box,
	Button,
	CardActions,
	CircularProgress,
	Container,
	Grid,
} from "@mui/material";
import { FirebaseContext } from "@/contexts/firebase-context";
import { Favorite, BathroomOutlined, BedroomParentOutlined } from "@mui/icons-material";
import { SuiteGroup } from "@/data/models";
import {
	findAllBuilding,
	findAllSuiteGroupsById,
	findAllWatchedListingsCode,
	updateWatchedListingsCode,
} from "@/data/services";

type PropsParam = {
	title: string;
};

export function WatchedView(props: PropsParam) {
	const {
		state: { user },
	} = useContext(FirebaseContext);
	const [suiteGroups, setSuiteGroups] = useState<Array<SuiteGroup>>([]);
	const [showLoading, setLoading] = useState<boolean>(true);
	const [watchList, setWatchList] = useState<Array<string>>([]);
	const [updateWatchList, setUpdateWatchList] = useState<number>(0);
	const [firstLoaderPage, setFirstLoaderPage] = useState(true);
	const [buildingHashMap, setBuildingHashMap] = useState<any>({});

	async function handleFindAllSuiteGroups() {
		const watchedList = await findAllWatchedListingsCode(user?.uid!);
		if (watchedList.length) {
			const response = await findAllSuiteGroupsById(watchedList);
			setSuiteGroups(response);
		} else {
			setSuiteGroups([]);
		}
		setLoading(false);
	}

	async function handleAddToWatchList(groupId: string) {
		if (watchList?.includes(groupId)) {
			updateWatchedListingsCode(user?.uid!, groupId, true);
			setWatchList((previous) => previous.filter((heart) => heart != groupId));
		} else {
			updateWatchedListingsCode(user?.uid!, groupId, false);
			setWatchList([...watchList, groupId]);
		}
		setUpdateWatchList((previous) => previous + 1);
	}

	async function handleBuildingHashMap() {
		const buildings = await findAllBuilding();
		const createBuildingHashMap: any = {};
		for (const building of buildings) {
			createBuildingHashMap[building.id!] = building;
		}
		setBuildingHashMap(createBuildingHashMap);
	}

	useEffect(() => {
		if (firstLoaderPage) {
			document.title = props.title;
			setFirstLoaderPage(false);
			handleBuildingHashMap();
		}

		if (user) {
			handleFindAllSuiteGroups();
			findAllWatchedListingsCode(user.uid).then((response) => setWatchList(response));
		}
	}, [user, updateWatchList]);

	return (
		<Box display="flex">
			<Container maxWidth="lg">
				<Box paddingY="36px">
					<Typography variant="h4" align="center" gutterBottom>
						Your dream property awaits
					</Typography>
				</Box>
				{showLoading && (
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
						<CircularProgress />
					</Box>
				)}
				{!showLoading && !suiteGroups.length && <Typography align="center">No suites found...</Typography>}
				<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
					{suiteGroups &&
						suiteGroups.map((suiteGroup) => (
							<Grid key={suiteGroup.id} xs={12} sm={4} md={3} item>
								<Card sx={{ minHeight: "400px", display: "flex", flexDirection: "column" }}>
									<CardMedia component="img" width="100%" height="200" image={suiteGroup.imageURL} />
									<CardContent>
										<Typography
											gutterBottom
											variant="h6"
											component="div"
											textOverflow={"ellipsis"}
											overflow={"hidden"}
											whiteSpace={"nowrap"}>
											{suiteGroup?.suiteGroupType ?? "n/a"}
										</Typography>
										<Typography gutterBottom variant="body2" color="text.secondary">
											{`${buildingHashMap[suiteGroup?.buildingID]?.streetAddress ?? "n/a"}, ${
												buildingHashMap[suiteGroup?.buildingID]?.city ?? ""
											}`}
										</Typography>
										{!!suiteGroup?.balconySqFt ? (
											<Typography
												variant="body2"
												color="text.secondary"
												textOverflow={"ellipsis"}
												overflow={"hidden"}
												whiteSpace={"nowrap"}>
												{`Indoor ${suiteGroup.balconySqFt} sq. ft. / Outdoor ${
													Number(suiteGroup?.totalSqFt) - Number(suiteGroup.balconySqFt) ??
													"n/a"
												} sq. ft.`}
											</Typography>
										) : null}
										<Typography gutterBottom variant="body2" color="text.secondary">
											{`Total ${suiteGroup?.totalSqFt || "n/a"} sq. ft.`}
										</Typography>
										<Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
											<BedroomParentOutlined />
											<Typography variant="body2" color="text.secondary">
												{suiteGroup?.bedroomCount ?? "n/a"} Bedrooms
											</Typography>
										</Box>
										<Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
											<BathroomOutlined />
											<Typography variant="body2" color="text.secondary">
												{suiteGroup?.bathroomCount ?? "n/a"} Bathrooms
											</Typography>
										</Box>
									</CardContent>
									<CardActions
										style={{
											paddingLeft: user ? "0" : "16px",
											marginTop: "auto",
											marginBottom: 16,
										}}>
										{user && (
											<IconButton
												sx={{ color: watchList?.includes(suiteGroup.id) ? "red" : "primary" }}
												onClick={() => handleAddToWatchList(suiteGroup.id)}>
												<Favorite />
											</IconButton>
										)}
										<Button
											component={Link}
											to={"/suites/" + suiteGroup.id}
											size="small"
											color="primary"
											variant="contained">
											View
										</Button>
									</CardActions>
								</Card>
							</Grid>
						))}
				</Grid>
			</Container>
		</Box>
	);
}
