import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { Box, Avatar, Button, CssBaseline, TextField, Link, Paper, Grid, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FirebaseContext } from "@/contexts/firebase-context";
import { ArrowBackIos } from "@mui/icons-material";

type PropsParam = {
  title: string
}

export function ResetPasswordView(props: PropsParam) {
  const navigate = useNavigate();
  const { state: { auth }} = useContext(FirebaseContext);
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const formFields = event.target as typeof event.target & {
      email: { value: string; error: boolean };
    };

    if (formFields.email.value) {
      sendPasswordResetEmail(auth, formFields.email.value, {
        handleCodeInApp: false,
        url: "https://localhost:8080",
      }).then(() => { 
        navigate("/signin")
      }).catch((error) => {
          const errorMessage = error.message;
          setEmailError(true);
          setEmailHelperText(errorMessage);
        });
    } else {
      setEmailError(true);
      setEmailHelperText("Please enter a valid Email address");
    }
  };

  useEffect(() => { 
    document.title = props.title
  }, [])
  
  return (
    <Box sx={{ backgroundColor: "white", height: "100vh", width: "100vw" }}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
          <Box sx={{ 
            display: 'flex', 
            pl: 4, 
            height: 56, 
            bgcolor: '#000', 
            alignItems: 'center',
          }}>
            <Typography
              onClick={() => navigate('/')}
              variant='h6'
              component='a'
              sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'capitalize',
                cursor: 'pointer',
                color: '#fff',
              }}
            >
              <ArrowBackIos sx={{ marginRight: '0.5rem' }} />
              Condoville Real Estate
            </Typography>
          </Box>
          <Box sx={{ my: 8, mx: 4, display: "flex", flexDirection: "column", alignItems: "center" }} >
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={emailError}
                helperText={emailHelperText}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link onClick={() => navigate('/signin')}>
                    <Typography variant="body2">Sign in?</Typography> 
                  </Link>
                </Grid>
                <Grid item>
                  <Link onClick={() => navigate('/signup')}>
                    <Typography variant="body2">Don&apos;t have an account? Sign Up</Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
