import { Fragment, useContext, useEffect, useState } from "react";
import { TableBody, TableRow, TableCell, TableHead, Table } from "@mui/material";
import { Box, Button, Container, css, Grid, Typography, TableContainer } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom"

import ImageGallery from "react-image-gallery";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { FirebaseContext } from "@/contexts/firebase-context";
import { EnquireModal } from "@/components/fragments";
import { SuiteGroup, Customer, ObjectKey, Suite, Building } from "@/data/models";
import { formatter, findBuildingById, findSuiteById, findCustomerById, DEFAULT_IMAGE_PLACEHOLDER, findImagePlaceholderByPath, findSuiteGroupById } from "@/data/services";
import { ffPrice } from "@/data/services/calculation-service";

type PropsParam = {
  title: string
}

export function SuiteDetailsView(props: PropsParam) {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { state: { user } } = useContext(FirebaseContext);
  const [suite, setSuite] = useState<Suite>();
  const [suiteGroup, setSuiteGroup] = useState<SuiteGroup>();
  const [building, setBuilding] = useState<Building>();
  const [customer, setCustomer] = useState<Customer>();
  const [showEnquireModal, setShowEnquireModal] = useState(false);
  const [firstLoaderPage, setFirstLoaderPage] = useState(true);
  const [suiteGroupImages, setSuiteGroupImages] = useState<Array<ObjectKey>>([{ original: DEFAULT_IMAGE_PLACEHOLDER, thumbnail: DEFAULT_IMAGE_PLACEHOLDER }]);

  function setTitle(title: string) {
    document.title = props.title + ' - ' + title
  }

  function isEnquired() {
    return customer?.propertyInquiries?.find((property: string) => property === suite?.suiteID);
  }

  function isHold() {
    return !!suite?.enquiryStatus && suite.enquiryStatus !== 'enquiry';
  }

  async function handlePageLoader() {
    const suiteResponse = await findSuiteById(params.id!);
    setSuite(suiteResponse);
    const building = await findBuildingById(suiteResponse.buildingID);
    setTitle(building.buildingName)
    setBuilding(building);
  }

  async function handleSuiteGroupImages() {
    const suiteGroupsResponse = await findSuiteGroupById(suite?.suiteGroupID!);
    setSuiteGroup(suiteGroupsResponse);
    if (suiteGroupsResponse.filePaths) {
      const images = await Promise.all(suiteGroupsResponse.filePaths.map(async path => {
        const imagePlaceholderResponse = findImagePlaceholderByPath(path);
        return {
          original: await imagePlaceholderResponse,
          thumbnail: await imagePlaceholderResponse
        }
      }));
      if (images.length) {
        setSuiteGroupImages(images);
      }
    }
  }

  useEffect(() => {
    if (firstLoaderPage) {
      setFirstLoaderPage(false);
      handlePageLoader();
    }
    if (user) {
      findCustomerById(user.uid).then(response => setCustomer(response));
    }
    if (suite) {
      handleSuiteGroupImages();
    }
  }, [user, suite]);

  return (
    <Box display="flex">
      {suite && suiteGroup && (
        <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
          <Box margin="10px 0 30px 0">
            <Button startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1)}>Back</Button>
          </Box>
          <Typography
            sx={{
              display: { xs: "block", sm: "none", md: "none", lg: "none" },
            }}
            variant="h3"
            align="left"
            gutterBottom
          >
            {building?.buildingName} {suite.suiteName}
          </Typography>
          <Box
            sx={{
              display: { xs: "block", sm: "none", md: "none", lg: "none" },
            }}
          >
            <Typography variant="h6" align="right" sx={{ color: "#999" }}>
              FOR SALE
            </Typography>
            <Typography variant="h5" align="right" gutterBottom>
              {suiteGroup ? formatter.format(ffPrice({
                ...suite,
                suiteGroup: suiteGroup
              })) : 'Loading...'}
            </Typography>
          </Box>

          <Grid
            container
            sx={{
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
          >
            <Grid item xs={6}>
              <Box>
                <Typography variant="h3" align="left" gutterBottom>
                  {building?.buildingName} {suite.suiteName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography variant="h6" align="right" sx={{ color: "#999" }}>
                  FOR SALE
                </Typography>
                <Typography variant="h4" align="right" gutterBottom>
                  {suiteGroup ? formatter.format(ffPrice({
                    ...suite,
                    suiteGroup: suiteGroup
                  })) : 'Loading...'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box maxWidth="md" overflow={'auto'} mt={2} >
            <table>
              <thead>
                <tr>
                  <th>
                    <Box sx={{ pr: 5 }}>
                      <Typography variant="subtitle2" align="left">
                        {suiteGroup.bedroomCount}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{ color: "#999" }}
                      >
                        BEDS
                      </Typography>
                    </Box>
                  </th>
                  <th>
                    <Box sx={{ px: 5 }}>
                      <Typography variant="subtitle2" align="left">
                        {suiteGroup.bathroomCount}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{ color: "#999" }}
                      >
                        BATHS
                      </Typography>
                    </Box>
                  </th>
                  <th>
                    <Box sx={{ px: 5 }}>
                      <Typography variant="subtitle2" align="left">
                        {suiteGroup.roomData ? suiteGroup.roomData.length : "-"}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{ color: "#999" }}
                      >
                        ROOMS
                      </Typography>
                    </Box>
                  </th>
                  <th>
                    <Box sx={{ px: 5 }}>
                      <Typography variant="subtitle2" align="left">
                        {suite?.sqFt || "-"}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{ color: "#999" }}
                      >
                        SQUARE FOOTAGE
                      </Typography>
                    </Box>
                  </th>
                </tr>
              </thead>
            </table>
          </Box>
          <Box minHeight={380}>
            <Box maxWidth="md" mt={3}>
              <ImageGallery
                disableThumbnailScroll={true}
                thumbnailPosition={"right"}
                showPlayButton={false}
                items={suiteGroupImages as { original: string, thumbnail: string }[]}
              />
            </Box>
          </Box>
          {suite.active && suite.salesStaff?.value && (
            <Button
              type="button"
              variant="contained"
              sx={{ mt: 3 }}
              onClick={() => setShowEnquireModal(true)}
              disabled={isHold() || !!isEnquired()}
            >
              Enquire
            </Button>
          )}
          <Box pt="2rem">
            <Typography variant="h6" align="left" gutterBottom>
              PROPERTY INFORMATION
            </Typography>
          </Box>
          <Box>
            <table
              css={css`
                width: 100%;
              `}
            >
              <tbody>
                <tr>
                  <td
                    css={css`
                      color: #999;
                      text-align: left;
                      width: 50%;
                    `}
                  >
                    BALCONY
                  </td>
                  <td
                    css={css`
                      text-align: left;
                      padding-left: 15%;
                      width: 50%;
                    `}
                  >
                    {suiteGroup.balcony}
                  </td>
                </tr>
                <tr>
                  <td
                    css={css`
                      color: #999;
                      text-align: left;
                      width: 50%;
                    `}
                  >
                    MAINTENANCE COMPANY
                  </td>
                  <td
                    css={css`
                      text-align: left;
                      padding-left: 15%;
                      width: 50%;
                    `}
                  >
                    {suiteGroup.maintenanceCompany}
                  </td>
                </tr>
                <tr>
                  <td
                    css={css`
                      color: #999;
                      text-align: left;
                      width: 50%;
                    `}
                  >
                    FINISH
                  </td>
                  <td
                    css={css`
                      text-align: left;
                      padding-left: 15%;
                      width: 50%;
                    `}
                  >
                    {suiteGroup.finish}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box>
            <table
              css={css`
                width: 100%;
              `}
            >
              <tbody>
                <tr>
                  <td
                    css={css`
                      color: #999;
                      text-align: left;
                      width: 50%;
                    `}
                  >
                    PARKING TYPE
                  </td>
                  <td
                    css={css`
                      text-align: left;
                      padding-left: 15%;
                      width: 50%;
                    `}
                  >
                    {building?.parkingType}
                  </td>
                </tr>
                <tr>
                  <td
                    css={css`
                      color: #999;
                      text-align: left;
                      width: 50%;
                    `}
                  >
                    PARKING
                  </td>
                  <td
                    css={css`
                      text-align: left;
                      padding-left: 15%;
                      width: 50%;
                    `}
                  >
                    {building?.parking?.label}
                  </td>
                </tr>
                <tr>
                  <td
                    css={css`
                      color: #999;
                      text-align: left;
                      width: 50%;
                    `}
                  >
                    COMMUNITY
                  </td>
                  <td
                    css={css`
                      text-align: left;
                      padding-left: 15%;
                      width: 50%;
                    `}
                  >
                    {suiteGroup.community}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <EnquireModal open={showEnquireModal} onClose={() => setShowEnquireModal(false)} suite={suite} />
          <Box pt="1rem">
            <Typography variant="h6" align="left" gutterBottom>
              PURCHASE INFO
            </Typography>
          </Box>
          <Grid container spacing={0} maxWidth="lg">
            <Grid item xs={12}>
              <Box sx={{ px: 0 }}>
                <table
                  css={css`
                    float: left;
                  `}
                >
                  <tbody>
                    {/* <tr>
                      <td
                        css={css`
                          color: #999;
                          text-align: right;
                        `}
                      >
                        DEPOSIT AMOUNT
                      </td>
                      <td
                        css={css`
                          text-align: right;
                          padding-left: 15%;
                        `}
                      >
                        {formatter.format(suite.reserveAmount)}
                      </td>
                    </tr> */}
                    <tr>
                      <td
                        css={css`
                          color: #999;
                          text-align: right;
                        `}
                      >
                        PRICE
                      </td>
                      <td
                        css={css`
                          text-align: right;
                          padding-left: 15%;
                        `}
                      >
                        {suiteGroup ? formatter.format(ffPrice({
                          ...suite,
                          suiteGroup: suiteGroup
                        })) : 'Loading...'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Grid>
          </Grid>
          {suiteGroup.roomData && suiteGroup.roomData.length && (
            <Fragment>
              <Typography variant="h6" align="left" sx={{ mt: 5 }} gutterBottom>
                ROOMS
              </Typography>
              <Grid container spacing={0} maxWidth="lg">
                <TableContainer>
                  <Table
                    padding="normal"
                    sx={{ minWidth: 400 }}
                    size="medium"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>ROOM</TableCell>
                        <TableCell align="right">WIDTH</TableCell>
                        <TableCell align="right">LENGTH</TableCell>
                        <TableCell align="right">TOTAL</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {suiteGroup.roomData && suiteGroup.roomData.map((room: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {room.room}
                          </TableCell>
                          <TableCell align="right">{room.width}</TableCell>
                          <TableCell align="right">{room.length}</TableCell>
                          <TableCell align="right">{room.totalSquareFeet}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Fragment>
          )}


          {user && (
            <Typography variant="h6" align="left" sx={{ mt: 5 }} gutterBottom>
              BUILDING
            </Typography>
          )}
          {user && building?.builder?.value && (
            <Button
              type="button"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 1 }}
              onClick={() => navigate('/builders/' + building.builder.value)}
            >
              View Builder
            </Button>
          )}
          {suite.active && suite.salesStaff?.value ? (
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => setShowEnquireModal(true)}
              disabled={isHold() || !!isEnquired()}
            >
              Enquire
            </Button>
          ) : null}
        </Container>
      )}
    </Box>
  );
};
