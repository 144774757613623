import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../services/firebase-service";

type LoaderProps = {
  params: any
}

export async function attachmentsLoader({ params }: LoaderProps) {
  const response = await getDocs(query(collection(db, 'storage_files'), where('journeyID', '==', params.id)))
  return response.docs.map(document => ({id: document.id, ...document.data()}))
}