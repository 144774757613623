import { getDocs, query, collection, orderBy, where, DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { CustomerGroup } from "@/data/models";
import { Building } from "../models/building";
import { db, findById } from "./firebase-service";

async function parserBuildingData(document: QueryDocumentSnapshot<DocumentData>) {
	return {
		id: document.id,
		...document.data(),
		createdDate: document.data().createdDate?.toDate(),
		updatedDate: document.data().updatedDate?.toDate(),
	} as Building;
}

export async function findAllBuilding() {
	const buildingsRef = collection(db, "buildings");
	const queryOptions = [where("active", "==", true)];
	const queryRef = query(buildingsRef, ...queryOptions);
	const querySnapshot = await getDocs(queryRef);
	return (await Promise.all(querySnapshot.docs.map(parserBuildingData))) as Array<Building>;
}

export async function findBuildingById(buildingId: string) {
	const buildingResponse = await findById(buildingId, "buildings");
	const parserData = {
		...buildingResponse,
		createdDate: buildingResponse.createdDate?.toDate(),
		updatedDate: buildingResponse.updatedDate?.toDate(),
	};
	return parserData as Building;
}
