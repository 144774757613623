import "../assets/scss/app.scss";
import { app, auth, db, storage } from "@/data/services"
import { createRoot } from "react-dom/client";
import { RootContainer } from "./router";

// window.ReactNativeWebView.postMessage(JSON.stringify({ key: 'LOGIN', value: 'login' }));

// if (
//   !window.hasOwnProperty("Bridge") &&
//   !(window.hasOwnProperty("webkit") && window.webkit.messageHandlers)
// ) {
//   window.fbAsyncInit = function () {
//     FB.init({
//       xfbml: true,
//       version: "v15.0",
//       appId: "624552032666388",
//     });
//     FB.CustomerChat.show(true);
//     FB.CustomerChat.showDialog();
//   };
//   (function (d, s, id) {
//     var js,
//       fjs = d.getElementsByTagName(s)[0];
//     if (d.getElementById(id)) return;
//     js = d.createElement(s);
//     js.id = id;
//     js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
//     fjs.parentNode.insertBefore(js, fjs);
//   })(document, "script", "facebook-jssdk");
// }

const container = document.getElementById("App") as Element;
const reactRoot = createRoot(container, {
  onRecoverableError: (error) => {
    console.log(`onRecoverableError: ${error}`);
  },
});

reactRoot.render(<RootContainer firebase={{ user: null, app, auth, db, storage }} />);