import { getDocs, query, collection, where, orderBy, QueryDocumentSnapshot, DocumentData } from "firebase/firestore";
import { Customer } from "../models/customer";
import { CustomerGroup } from "../models/customer-group";
import { Suite } from "../models/suite";
import { db, findById } from "./firebase-service";
import { findImagePlaceholderByPath } from "./image-service";

async function parserSuiteData(document: QueryDocumentSnapshot<DocumentData>) {
  return ({
    id: document.id,
    ...document.data(),
    imageURL: await findImagePlaceholderByPath(document.data().defaultImagePath) as string,
    createdDate: document.data().createdDate?.toDate(),
    updatedDate: document.data().updatedDate?.toDate(),
  }) as Suite
}

async function parserSuiteDataNoImage(document: QueryDocumentSnapshot<DocumentData>) {
  return ({
    id: document.id,
    ...document.data(),
    createdDate: document.data().createdDate?.toDate(),
    updatedDate: document.data().updatedDate?.toDate(),
  }) as Suite
}

export async function findSuiteById(suiteId: string) {
  const suiteResponse = await findById(suiteId, "suites");
  const parserData = {
    ...suiteResponse,
    createdDate: suiteResponse.createdDate?.toDate(),
    updatedDate: suiteResponse.updatedDate?.toDate(),
  }
  return parserData as Suite;
}

export async function findAllSuitesBySuiteGroupId(suiteGroupId: string) {
  const snapshots = await getDocs(
    query(
      collection(db, "suites"),
      orderBy("updatedDate", "desc"),
      where("suiteGroupID", "==", suiteGroupId)
    )
  );
  return await Promise.all(snapshots.docs.map(parserSuiteDataNoImage)) as Array<Suite>;
}
