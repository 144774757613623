import { useContext, useEffect, useState } from "react";
import { Checkbox, FormGroup, Typography, Container, Chip, Avatar } from "@mui/material";
import { Box, Button, TextField, Alert, FormControlLabel } from "@mui/material";

import { updatePassword } from "firebase/auth";
import { FirebaseContext } from "@/contexts/firebase-context";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

type PropsParam = {
	title: string;
};

export function AccountView(props: PropsParam) {
	const navigate = useNavigate();
	const {
		state: { auth, db },
	} = useContext(FirebaseContext);
	const [showSuccessAlert, setSuccessAlert] = useState(false);
	const [showErrorAlert, setErrorAlert] = useState<string>("");
	const [smsChecked, setSMSChecked] = useState(false);
	const [pushChecked, setPushChecked] = useState(false);
	const [emailChecked, setEmailChecked] = useState(false);
	const [inputAgent, setInputAgent] = useState({ image: "", email: "" } as { image?: string; email: string });
	const [inputValues, setInputValues] = useState({
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		address: "",
		postalCode: "",
		city: "",
		province: "",
		country: "",
		password: "",
		agent: "",
	});

	function inputChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
		setInputValues((currentInputValues) => ({
			...currentInputValues,
			[event.target.name]: event.target.value,
		}));
	}

	function handleFormSubmit(event: React.MouseEvent<HTMLElement>) {
		event.preventDefault();
		setDoc(
			doc(db, "customers", auth.currentUser!.uid),
			{
				updatedDate: serverTimestamp(),
				firstName: inputValues.firstName,
				lastName: inputValues.lastName,
				phone: inputValues.phone,
				email: inputValues.email,
				address: inputValues.address,
				postalCode: inputValues.postalCode,
				city: inputValues.city,
				province: inputValues.province,
				country: inputValues.country,
				agent: inputValues.agent,
				preferences: {
					sms: smsChecked,
					push: pushChecked,
					email: emailChecked,
				},
			},
			{ merge: true }
		)
			.then(() => {
				if (inputValues.password.length > 0) {
					updatePassword(auth.currentUser!, inputValues.password)
						.then(() => {
							setSuccessAlert(true);
						})
						.catch((err) => {
							console.log(err);
							setErrorAlert(err.code);
						});
				} else {
					setSuccessAlert(true);
				}
			})
			.catch(console.error);
	}

	function handleRemoveAgent() {
		setInputAgent((old) => ({ ...old, email: "" }));
		setInputValues((old) => ({ ...old, agent: "" }));
	}

	function handleFindAgent(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) {
		setInputAgent({ email: event.target.value });
	}

	useEffect(() => {
		document.title = props.title;
		if (auth.currentUser) {
			const docRef = doc(db, "customers", auth.currentUser.uid);
			getDoc(docRef).then((docSnap) => {
				const customerProfile = docSnap.data();
				if (customerProfile) {
					// console.log(customerProfile);
					setInputValues({
						firstName: customerProfile.firstName ?? "",
						lastName: customerProfile.lastName ?? "",
						phone: customerProfile.phone ?? "",
						email: customerProfile.email ?? "",
						address: customerProfile.address ?? "",
						postalCode: customerProfile.postalCode ?? "",
						city: customerProfile.city ?? "",
						province: customerProfile.province ?? "",
						country: customerProfile.country ?? "",
						password: "",
						agent: customerProfile.agent ?? "",
					});
					setInputAgent({ email: customerProfile.agent ?? "" });
					setSMSChecked((customerProfile.preferences ?? { sms: false }).sms ?? false);
					setPushChecked((customerProfile.preferences ?? { push: false }).push ?? false);
					setEmailChecked((customerProfile.preferences ?? { email: false }).email ?? false);
				}
			});
		}
	}, [db, auth.currentUser, setInputValues, setSMSChecked, setPushChecked, setEmailChecked]);

	return (
		<Box display="flex">
			<Container
				maxWidth="lg"
				sx={{
					mt: { xs: "0", md: "4", lg: "4", xl: "4" },
					mb: { xs: "0", md: "4", lg: "4", xl: "4" },
				}}>
				<Box sx={{ paddingTop: { xs: "50px", md: "36px" }, paddingBottom: "32px" }}>
					<Typography variant="h4" align="center" gutterBottom>
						Account
					</Typography>
				</Box>
				<Box sx={{ p: { xs: 0, sm: 0, md: 15, lg: 15, xl: 15 } }}>
					<Box style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
						<TextField
							onChange={inputChangeHandler}
							value={inputValues.firstName}
							margin="normal"
							required
							fullWidth
							id="firstName"
							label="First name"
							name="firstName"
							autoComplete="given-name"
						/>
						<TextField
							onChange={inputChangeHandler}
							value={inputValues.lastName}
							margin="normal"
							required
							fullWidth
							id="lastName"
							label="Last name"
							name="lastName"
							autoComplete="family-name"
						/>
					</Box>
					<TextField
						onChange={inputChangeHandler}
						value={inputValues.phone}
						margin="normal"
						required
						fullWidth
						id="phone"
						label="Phone number"
						name="phone"
						autoComplete="tel"
					/>
					<TextField
						onChange={inputChangeHandler}
						value={inputValues.email}
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email address"
						name="email"
						autoComplete="email"
					/>
					{inputAgent.email.length == 0 ? (
						<TextField
							style={{ flex: 1 }}
							onChange={inputChangeHandler}
							onBlur={handleFindAgent}
							value={inputValues.agent}
							margin="normal"
							fullWidth
							name="agent"
							label="Agent Email (Optional)"
							id="agent"
							autoComplete="off"
						/>
					) : (
						<Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
							<Typography variant="h6" gutterBottom>
								Your Agent
							</Typography>
							<Box>
								<Chip
									avatar={<Avatar alt={inputAgent.email} src={inputAgent.image} />}
									label={inputValues.agent}
									variant="outlined"
									onDelete={handleRemoveAgent}
								/>
							</Box>
						</Box>
					)}
					<TextField
						onChange={inputChangeHandler}
						value={inputValues.password}
						margin="normal"
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="new-password"
					/>
					<TextField
						onChange={inputChangeHandler}
						value={inputValues.address}
						margin="normal"
						fullWidth
						name="address"
						label="Address"
						id="address"
						autoComplete="off"
					/>
					<TextField
						onChange={inputChangeHandler}
						value={inputValues.postalCode}
						margin="normal"
						fullWidth
						name="postalCode"
						label="Postal code"
						id="postalCode"
						autoComplete="off"
					/>
					<TextField
						onChange={inputChangeHandler}
						value={inputValues.city}
						margin="normal"
						fullWidth
						name="city"
						label="City"
						id="city"
						autoComplete="off"
					/>
					<TextField
						onChange={inputChangeHandler}
						value={inputValues.province}
						margin="normal"
						fullWidth
						name="province"
						label="Province"
						id="province"
						autoComplete="off"
					/>
					<TextField
						onChange={inputChangeHandler}
						value={inputValues.country}
						margin="normal"
						fullWidth
						name="country"
						label="Country"
						id="country"
						autoComplete="off"
					/>

					<Typography variant="h5" align="center">
						Notification Preferences
					</Typography>

					<FormGroup sx={{ alignItems: "center" }}>
						<FormControlLabel
							control={
								<Checkbox
									onChange={() => {
										setSMSChecked(!smsChecked);
									}}
									checked={smsChecked}
								/>
							}
							label="SMS"
							labelPlacement="start"
						/>

						<FormControlLabel
							control={
								<Checkbox
									onChange={() => {
										setPushChecked(!pushChecked);
									}}
									checked={pushChecked}
								/>
							}
							label="PUSH"
							labelPlacement="start"
						/>

						<FormControlLabel
							control={
								<Checkbox
									onChange={() => {
										setEmailChecked(!emailChecked);
									}}
									checked={emailChecked}
								/>
							}
							label="EMAIL"
							labelPlacement="start"
						/>
					</FormGroup>
					{showSuccessAlert && (
						<Alert variant="outlined" severity="success">
							Changes submitted successfully!
						</Alert>
					)}
					{showErrorAlert.length > 0 && (
						<Alert variant="outlined" severity="error">
							{showErrorAlert}
						</Alert>
					)}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
						onClick={handleFormSubmit}>
						Update
					</Button>
					<Button
						type="button"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
						onClick={() => {
							auth.signOut().then(() => {
								navigate("/signin");
								window.location.reload();
							});
						}}>
						Logoff
					</Button>
				</Box>
			</Container>
		</Box>
	);
}
