import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import moment from "moment"
import { getDownloadURL, ref } from "firebase/storage";
import { AttachFile } from "@mui/icons-material";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { Box, Container, Typography, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { storage } from "@/data/services";
import { UploadFileModal } from "@/components/fragments";

type PropsParam = {
  title: string
}
export function AttachFileView(props: PropsParam) {
  const params = useParams()
  const navigate = useNavigate()
  const attachments = useLoaderData() as Array<{ title: string, ownerID: string, fullPath: string, updatedDate: any, fileSize: number }>
  const [open, setOpen] = useState(false);

  function handleDownloadAttachmentFile(fullPath: string) {
    getDownloadURL(ref(storage, fullPath)).then((url) => {
      window.open(url, "_blank");
    }).catch(console.error);
  };
  
  useEffect(() => {
    document.title = props.title
  }, []);

  function handleFileSizeBytes(bytes: number) {
    if (bytes >= 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else {
      return (bytes / 1024).toFixed(2) + " KB";
    }
  }

  return (
    <Box display="flex">
      <UploadFileModal journeyId={params.id!} open={open} onClose={() => setOpen(false)} />
    <Container maxWidth="lg">
      <Box marginTop="2rem">
        <Button startIcon={<ArrowBackIosNew />} onClick={() => navigate(-1)}>Back</Button>
      </Box>
      <Box paddingY="36px">
        <Typography variant="h4" align="center" gutterBottom>
          Attachments Files
        </Typography>
      </Box>
      <Box>
        <Box bgcolor="background.paper" width="100%">
          <Box width="100%" display="flex" flexDirection="row" gap="10px" justifyContent="end" height="40px">
            <Button variant="outlined" onClick={() => setOpen(true)}>UPLOAD</Button>
          </Box>
          <List>
            {attachments.map((file, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => handleDownloadAttachmentFile(file.fullPath)} >
                  <ListItemIcon>
                    <AttachFile />
                  </ListItemIcon>
                  <ListItemText primary={file.title} secondary={"File size: "+ handleFileSizeBytes(file.fileSize) +" | Last updated: " + moment(file.updatedDate?.toDate()).format("D MMM YYYY, h:mm:ss a")} />
                </ListItemButton>
              </ListItem>
            ))}
            {!attachments.length && <Typography align="center">No attachments found...</Typography>}
          </List>
        </Box>
      </Box>
    </Container>
  </Box>
  )
} 