import { useEffect } from "react";
import { ListItemButton, ListItemText, Typography, ListItemIcon } from "@mui/material";
import { Box, Container, ListItem, List } from "@mui/material";
import { Folder } from "@mui/icons-material";
import { useLoaderData, useNavigate } from "react-router-dom"

type PropsParam = {
  title: string
}

export function EnquiredPropertiesView(props: PropsParam) {
  const journeys = useLoaderData() as Array<{ id: string, displayName: string}>
  const navigate = useNavigate();


  useEffect(() => {
    document.title = props.title
  }, []);

  return (
    <Box display="flex">
      <Container maxWidth="lg">
        <Box paddingY="36px">
          <Typography variant="h4" align="center" gutterBottom>
            Enquired Properties
          </Typography>
        </Box>
        <Box>
          <Box bgcolor="background.paper" width="100%" gap="10px">
            <List>
              {journeys.map((folder, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => navigate('/enquired-properties/' + folder.id)}>
                    <ListItemIcon>
                      <Folder />
                    </ListItemIcon>
                    <ListItemText primary={folder.displayName} />
                  </ListItemButton>
                </ListItem>
              ))}
              {!journeys.length && <Typography align="center">No journeys found...</Typography>}
            </List>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
