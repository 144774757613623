import { useContext, useEffect, useState } from 'react';
import { Box, Avatar, Button, CssBaseline, TextField, Link, Paper, Grid, Typography } from '@mui/material';
import { css } from '@emotion/react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '@/contexts/firebase-context';
import { ArrowBackIos } from '@mui/icons-material';

type PropsParam = {
  title: string;
};

export const RegisterView = (props: PropsParam) => {
  const navigate = useNavigate();
  const {
    state: { auth, db },
  } = useContext(FirebaseContext);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  async function createCustomerAccount(
    emailAddress: string,
    password: string,
    phone: string,
    firstName: string,
    lastName: string,
    agent: string
  ): Promise<void> {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        emailAddress,
        password
      );
      if (userCredential) {
        setHelperText('');
        setError(false);
      }

      return setDoc(doc(db, 'customers', userCredential.user.uid), {
        active: true,
        createdDate: new Date(),
        updatedDate: new Date(),
        customerGroup: ['DORgsBY8wtX9lrjesJCU'],
        firstName,
        lastName,
        phone,
        email: emailAddress,
        agent,
        preferences: {
          sms: true,
          push: true,
          email: true,
        },
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formFields = event.target as typeof event.target & {
      email: { value: string; error: boolean };
      password: { value: string; error: boolean };
      phone: { value: string; error: boolean };
      firstName: { value: string; error: boolean };
      lastName: { value: string; error: boolean };
      agent: { value: string; error: boolean };
    };

    if (
      formFields.email.value &&
      formFields.password.value &&
      formFields.phone.value &&
      formFields.firstName.value &&
      formFields.lastName.value
    ) {
      createCustomerAccount(
        formFields.email.value,
        formFields.password.value,
        formFields.phone.value,
        formFields.firstName.value,
        formFields.lastName.value,
        formFields.agent.value
      )
        .then(() => {
          navigate('/signin');
        })
        .catch((err) => {
          setError(true);
          setHelperText(err.code);
        });
    } else {
      setError(true);
      setHelperText('Please enter a valid Password and Email address');
    }
  }

  useEffect(() => {
    document.title = props.title;
  }, []);

  return (
    <Box sx={{ backgroundColor: 'white', height: '100vh', width: '100vw' }}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} sx={{ backgroundColor: '#212121', borderRight: '5px solid #fff' }}>
          <Box
            component='div'
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            <h1
              css={css`
                position: initial;
                font-family: initial;
                font-size: 15vw;
                margin: 0;
                letter-spacing: 1.2vw;
                position: absolute;
                top: 3vh;
                left: 5%;
                color: white;
                background: url('https://condovillerealestate.ca/wp-content/uploads/2021/07/DSC04036-scaled.jpg');
                background-size: auto 70%;
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: shine 8s linear infinite;
                &:nth-child(2) {
                  top: 50vh;
                }
                @keyframes shine {
                  from {
                    background-position: center 0;
                  }
                  to {
                    background-position: center 70%;
                  }
                }
              `}
            >
              CVRE
            </h1>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ 
            display: 'flex', 
            pl: 4, 
            height: 56, 
            bgcolor: '#000', 
            alignItems: 'center',
          }}>
            <Typography
              onClick={() => navigate('/')}
              variant='h6'
              component='a'
              sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'capitalize',
                cursor: 'pointer',
                color: '#fff',
              }}
            >
              <ArrowBackIos sx={{ marginRight: '0.5rem' }} />
              Condoville Real Estate
            </Typography>
          </Box>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign up
            </Typography>
            <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='firstName'
                label='First name'
                name='firstName'
                autoComplete='given-name'
                autoFocus
              />
              <TextField
                margin='normal'
                required
                fullWidth
                id='lastName'
                label='Last name'
                name='lastName'
                autoComplete='family-name'
              />
              <TextField
                margin='normal'
                required
                fullWidth
                id='phone'
                label='Phone number'
                name='phone'
                autoComplete='tel'
              />
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email address'
                autoCapitalize='off'
                autoCorrect='off'
                name='email'
                autoComplete='email'
                error={error}
                helperText={helperText}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='new-password'
                error={error}
                helperText={helperText}
              />
              <TextField
                margin='normal'
                fullWidth
                name='agent'
                label='Agent Email (Optional)'
                id='agent'
                autoComplete='agent'
                error={error}
                helperText={helperText}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
                Create account
              </Button>
              <Grid container>
                <Grid item>
                  <Link onClick={() => navigate('/signin')}>
                    <Typography variant='body2'>Sign in instead</Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
