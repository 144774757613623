import { Modal, Box, Card, CardContent, Typography, Button, DialogTitle } from "@mui/material"
import { addDoc, collection, setDoc, doc } from "firebase/firestore"
import { Fragment, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { FirebaseContext } from "@/contexts/firebase-context"
import { Customer, Staff, Suite } from "@/data/models"
import { formatter, db, auth, findStaffById, createAnalyticalLog, findCustomerById } from "@/data/services"
import { LoginRegisterModal } from "@/components/fragments"

type PropsParam = {
  open: boolean
  onClose: Function
  suite: Suite
}

export function EnquireModal(props: PropsParam) {
  const navigate = useNavigate();
  const {state: { user }} = useContext(FirebaseContext);
  const [staff, setStaff] = useState<Staff | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);

  function handleOnCloseModal() {
    setCustomer(null);
    setStaff(null);
    props.onClose();
  }

  async function handleButtonSubmit() {
    addDoc(collection(db, "journeys"), {
      active: true,
      createdDate: new Date(),
      customerID: user?.uid,
      displayName: `${props.suite.suiteName}, ${props.suite.building.label}`,
      suiteID: props.suite.suiteID,
      status: "enquiry",
      enquiryStatus: "enquiry", 
    })
    .then(() => {
      setDoc(doc(db, "customers", user?.uid!),
        customer?.propertyInquiries?.length ? {
          propertyInquiries: [...customer?.propertyInquiries, props.suite.suiteID]
        } : {
          propertyInquiries: [props.suite.suiteID]
        }, {
          merge: true
        })
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      createAnalyticalLog(auth, db, 9, {
        suiteID: props.suite.suiteID,
      }).then(() => {
        console.log('send');
        navigate(0);
      });
    });
  }
  
  useEffect(() => {
    if (user) {
      findCustomerById(user.uid).then(response => setCustomer(response));
    }
    if (props.suite?.salesStaff) {
      findStaffById(props.suite.salesStaff.value).then(response => setStaff(response));
    }
  }, [user, props.suite]);

  return (
    <Fragment>
      {!user && <LoginRegisterModal open={props.open} onClose={props.onClose} />}
      {user && <Modal
        open={props.open}
        onClose={handleOnCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          width="400px"
          textAlign="center"
          sx={{transform: "translate(-50%, -50%)", p: 4}}
        >
          { staff && (
            <Fragment>
              <Card>
                <DialogTitle>
                  {props.suite.building.label} {props.suite.suiteName}
                  <br />
                  {formatter.format(props.suite.priceMax)}
                </DialogTitle>
                <CardContent>
                  <img
                    src={staff.imageURL}
                    style={{ width: "30%", objectFit: "scale-down" }}
                  />
                  <Typography variant="h6" component="h2">
                    {staff.firstName} {staff.lastName}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    Email: {staff.email}
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Phone: {staff.phone}
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Description: {staff.salesDescription}
                  </Typography>
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleOnCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, ml: 4 }}
                    onClick={handleButtonSubmit}
                  >
                    Confirm
                  </Button>
                </CardContent>
              </Card>
            </Fragment>
          )}
        </Box>
      </Modal>}
    </Fragment>

  )
}