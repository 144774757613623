import { ArrowUpward } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
export function ScrollToTopButton() {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box position="fixed" bottom="75px" right="10px">
    <IconButton
    color="secondary"
      onClick={handleScrollToTop}
      sx={{
        backgroundColor: '#111',
        '&:hover': {
          backgroundColor: '#555',
        }
      }}
    >
      <ArrowUpward />
    </IconButton>
    </Box>

  );
};