import { getDocs, query, collection, where, documentId, QueryDocumentSnapshot, DocumentData } from "firebase/firestore"
import { CustomerGroup } from "../models/customer-group";
import { db } from "./firebase-service"

async function parserCustomerGroupData(document: QueryDocumentSnapshot<DocumentData>) {
  return ({
    id: document.id,
    ...document.data(),
    createdDate: document.data().createdDate?.toDate(),
    updatedDate: document.data().updatedDate?.toDate(),
  }) as CustomerGroup
}

export async function findPublicGroup() {
  const responseCustomerGroup = await getDocs(
    query(
      collection(db, "customer_groups"), 
      where("groupName", "==", "Public")
    )
  );
  const parserCustomerGroup = await Promise.all(responseCustomerGroup.docs.map(parserCustomerGroupData));
  return parserCustomerGroup[0] as CustomerGroup;
}

export async function findAllCustomerGroupById(customerGroupIds: Array<string>) {
  const responseCustomerGroup = await getDocs(
    query(
      collection(db, "customer_groups"), 
      where(documentId(), "in", customerGroupIds)
    )
  );
  const parserCustomerGroup = await Promise.all(responseCustomerGroup.docs.map(parserCustomerGroupData));
  return parserCustomerGroup as Array<CustomerGroup>;
}