import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import ReactImageGallery from "react-image-gallery";
import {
	Box,
	Container,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	CircularProgress,
} from "@mui/material";
import {
	ArrowBackIosNew,
	LockClock,
	Sell,
	EventAvailable,
	BathroomOutlined,
	BedroomParentOutlined,
	Signpost,
	Deselect,
	Deck,
} from "@mui/icons-material";
import { EnquireModal } from "@/components/fragments";
import { Building, Customer, Suite, SuiteGroup } from "@/data/models";
import { FirebaseContext } from "@/contexts/firebase-context";
import {
	formatter,
	findCustomerById,
	findSuiteGroupById,
	DEFAULT_IMAGE_PLACEHOLDER,
	findImagePlaceholderByPath,
	findBuildingById,
} from "@/data/services";
import { findAllSuitesBySuiteGroupId } from "@/data/services";
import { ffPrice } from "@/data/services/calculation-service";
import { isPdfUrl, generatePDFpreview } from "@/components/tools";

type PropsParam = {
	title: string;
};

export function SuitesView(props: PropsParam) {
	const params = useParams<{ id: string }>();
	const navigate = useNavigate();
	const {
		state: { user },
	} = useContext(FirebaseContext);
	const [showEnquireModal, setShowEnquireModal] = useState<boolean>(false);
	const [suites, setSuites] = useState<Array<Suite>>([]);
	const [filteredSuites, setFilteredSuites] = useState<Array<Suite>>([]);
	const [selectedSuite, setSelectedSuite] = useState<Suite | undefined>(undefined);
	const [showLoading, setShowLoading] = useState<boolean>(true);
	const [showLoadingSuiteGroup, setShowLoadingSuiteGroup] = useState<boolean>(true);
	const [customer, setCustomer] = useState<Customer | undefined>(undefined);
	const [suiteGroup, setSuiteGroup] = useState<SuiteGroup | undefined>(undefined);
	const [suiteGroupImages, setSuiteGroupImages] = useState([
		{ original: DEFAULT_IMAGE_PLACEHOLDER, thumbnail: DEFAULT_IMAGE_PLACEHOLDER },
	]);
	const [building, setBuilding] = useState<Building | undefined>(undefined);
	const location = useLocation();

	function handleOpenEnquireModal(selectedSuite: Suite) {
		setShowEnquireModal(true);
		setSelectedSuite(selectedSuite);
	}

	function handleCloseEnquireModal() {
		setSelectedSuite(undefined);
		setShowEnquireModal(false);
	}

	async function handlePublicListView() {
		try {
			const suitesResponse = await findAllSuitesBySuiteGroupId(params.id!);
			setSuites(suitesResponse);
		} catch (error) {
			console.error("Error fetching public suite list", error);
		} finally {
			setShowLoading(false);
		}
	}

	async function handleLoggedUserListView() {
		try {
			const customerResponse = await findCustomerById(user?.uid!);
			const suitesResponse = await findAllSuitesBySuiteGroupId(params.id!);
			setSuites(suitesResponse);
			setCustomer(customerResponse);
		} catch (error) {
			console.error("Error fetching user suite list", error);
		} finally {
			setShowLoading(false);
		}
	}

	function handleOnHoldStatus(status: string) {
		return !!status && status !== "enquiry" && status !== "available";
	}

	function handleEnquireStatus(suiteId: string) {
		const result = customer?.propertyInquiries?.find((property: string) => property === suiteId);
		return !!result;
	}

	async function handleSuiteGroups() {
		try {
			const suiteGroupsResponse = await findSuiteGroupById(params.id!);
			const responseBuilding = await findBuildingById(suiteGroupsResponse.buildingID);
			setBuilding(responseBuilding);
			setSuiteGroup(suiteGroupsResponse);
			if (suiteGroupsResponse.filePaths) {
				const images = [];
				for (const path of suiteGroupsResponse.filePaths) {
					const imagePlaceholderResponse = await findImagePlaceholderByPath(path);
					const isPdf = await isPdfUrl(imagePlaceholderResponse);
					if (isPdf) {
						const previewPDF = await generatePDFpreview(imagePlaceholderResponse);
						images.push({
							original: previewPDF,
							thumbnail: previewPDF,
						});
					} else {
						images.push({
							original: imagePlaceholderResponse,
							thumbnail: imagePlaceholderResponse,
						});
					}
				}
				setSuiteGroupImages(images);
			}
		} catch (error) {
			console.error("Error fetching suite groups", error);
		} finally {
			setShowLoadingSuiteGroup(false);
		}
	}

	useEffect(() => {
		document.title = props.title;
		if (user) {
			handleLoggedUserListView();
		} else {
			handlePublicListView();
		}
		handleSuiteGroups();
	}, [props.title, user]);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const buildingName = params.get("building_name")?.toLowerCase() ?? "";
		const city = params.get("city")?.toLowerCase() ?? "";
		const priceRange = params.get("price_range") ?? "";
		const bedroomsParam = params.get("bedrooms") ?? "";
		const bathroomsParam = params.get("bathrooms") ?? "";

		const bedroomsArray = bedroomsParam.split(",").filter(Boolean);
		const bathroomsArray = bathroomsParam.split(",").filter(Boolean);

		const filtered = suites.filter((suite) => {
			let match = true;

			if (buildingName) {
				match = match && suite.buildingName?.toLowerCase().includes(buildingName);
			}

			if (city) {
				match = match && suite.city?.toLowerCase().includes(city);
			}

			if (priceRange) {
				const [minPrice, maxPrice] = priceRange
					.split("-")
					.map((price) => parseInt(price.replace(/,/g, ""), 10));
				const suitePrice = parseInt(suite.price.replace(/,/g, ""), 10);
				match = match && suitePrice >= minPrice && (!maxPrice || suitePrice <= maxPrice);
			}

			if (bedroomsArray.length > 0) {
				match = match && bedroomsArray.includes(suite.bedrooms?.toString());
			}

			if (bathroomsArray.length > 0) {
				match = match && bathroomsArray.includes(suite.bathrooms?.toString());
			}

			return match;
		});

		setFilteredSuites(filtered);
	}, [suites, location.search]);

	return (
		<Box display="flex">
			<EnquireModal open={showEnquireModal} onClose={handleCloseEnquireModal} suite={selectedSuite} />
			<Container maxWidth="lg">
				<Box margin="60px 0 30px 0">
					<Button startIcon={<ArrowBackIosNew />} onClick={() => navigate(-1)}>
						Back
					</Button>
				</Box>
				<Box paddingY="36px">
					<Typography variant="h4" align="center" gutterBottom>
						{suiteGroup?.suiteGroupType ?? "Loading..."}
					</Typography>
				</Box>
				<Box>
					<Box bgcolor="background.paper" width="100%" gap="10px">
						<Box display="flex" justifyContent="center" mb="30px">
							<Box flex="1" minHeight="400px" display="flex">
								{showLoadingSuiteGroup && (
									<Box margin="auto" display="flex" justifyContent="center" alignItems="center">
										<CircularProgress />
									</Box>
								)}
								{!showLoadingSuiteGroup && (
									<Box display="flex" flexDirection="column" gap="20px" height="100px" mt="80px">
										<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
											<Signpost />
											<Typography variant="body1" color="text.secondary">
												{building?.streetAddress ?? "n/a"} {building?.city ?? ""}
											</Typography>
										</Box>
										{!!suiteGroup?.balconySqFt && (
											<Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
												<Deck />
												<Typography
													variant="body1"
													color="text.secondary"
													textOverflow={"ellipsis"}
													overflow={"hidden"}
													whiteSpace={"nowrap"}>
													Outdoor {suiteGroup.balconySqFt} sq. ft. / Indoor{" "}
													{Number(suiteGroup?.totalSqFt) - Number(suiteGroup.balconySqFt) ??
														"n/a"}{" "}
													sq. ft.
												</Typography>
											</Box>
										)}
										<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
											<Deselect />
											<Typography variant="body1" color="text.secondary">
												Total {suiteGroup?.totalSqFt || "n/a"} sq. ft.
											</Typography>
										</Box>
										<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
											<BedroomParentOutlined />
											<Typography variant="body1" color="text.secondary">
												{suiteGroup?.bedroomCount ?? "n/a"} Bedrooms
											</Typography>
										</Box>
										<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
											<BathroomOutlined />
											<Typography variant="body1" color="text.secondary">
												{suiteGroup?.bathroomCount ?? "n/a"} Bathrooms
											</Typography>
										</Box>
									</Box>
								)}
							</Box>
							<Box maxWidth="sm" sx={{ mt: 3 }} alignItems="center" justifyContent="center">
								<ReactImageGallery
									disableThumbnailScroll={true}
									thumbnailPosition={"right"}
									showPlayButton={false}
									items={suiteGroupImages}
								/>
							</Box>
						</Box>
						<TableContainer>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Suite Number</TableCell>
										<TableCell>Suite Floor</TableCell>
										<TableCell>Parking</TableCell>
										<TableCell>Storage</TableCell>
										<TableCell>Exposure</TableCell>
										<TableCell>Price</TableCell>
										<TableCell>Status</TableCell>
										<TableCell></TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredSuites.map((suite) => (
										<TableRow key={suite.id}>
											<TableCell component="th" scope="row">
												{suite.suite}
											</TableCell>
											<TableCell>{suite.floor}</TableCell>
											<TableCell>{suite.parking?.label}</TableCell>
											<TableCell>{suite.storage?.label}</TableCell>
											<TableCell>{suite.exposure?.label}</TableCell>
											<TableCell>
												{suiteGroup
													? formatter.format(
															ffPrice({
																...suite,
																suiteGroup: suiteGroup,
															})
													  )
													: "Loading..."}
											</TableCell>
											<TableCell>
												<Box
													display="flex"
													flexDirection="row"
													alignItems="center"
													ml="auto"
													gap={1}>
													{suite.active &&
													handleOnHoldStatus(suite?.enquiryStatus! || suite?.saleStatus) ? (
														<React.Fragment>
															<LockClock />
															<Typography>ON HOLD</Typography>
														</React.Fragment>
													) : !suite.active ? (
														<React.Fragment>
															<Sell />
															<Typography>SOLD</Typography>
														</React.Fragment>
													) : (
														<React.Fragment>
															<EventAvailable />
															<Typography>AVAILABLE</Typography>
														</React.Fragment>
													)}
												</Box>
											</TableCell>
											<TableCell>
												<Button
													component={Link}
													to={"/suites/" + params.id + "/details/" + suite.id}
													size="small"
													color="primary"
													variant="contained">
													View
												</Button>
											</TableCell>
											<TableCell>
												{suite?.active && suite?.salesStaff?.value && (
													<Button
														disabled={
															handleOnHoldStatus(
																suite?.enquiryStatus! || suite?.saleStatus
															) || handleEnquireStatus(suite?.suiteID)
														}
														onClick={() => handleOpenEnquireModal(suite)}
														size="small"
														color="primary"
														variant="contained">
														Enquire
													</Button>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						{showLoading && (
							<Box mt="50px" display="flex" justifyContent="center" alignItems="center" mb={3}>
								<CircularProgress />
							</Box>
						)}
						{!suites.length && !showLoading && (
							<Box mt="50px">
								<Typography align="center">No suites found...</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Container>
		</Box>
	);
}
