import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "./firebase-service";

export const DEFAULT_IMAGE_PLACEHOLDER = require("../../../assets/images/Image_placeholder.jpg");
export const DEFAULT_IMAGE_AVATAR =
	"https://t4.ftcdn.net/jpg/04/78/61/15/360_F_478611505_erMSwSaftg24Nvhr8wz4wMZc3v5emEyf.jpg";

export async function findImagePlaceholderByPath(path: string) {
	if (!path) {
		return DEFAULT_IMAGE_PLACEHOLDER;
	}
	try {
		const imageURL = await getDownloadURL(ref(storage, path));
		return imageURL;
	} catch (error) {
		console.error(error);
		return DEFAULT_IMAGE_PLACEHOLDER;
	}
}

export async function findImageAvatarByPath(path: string) {
	if (!path) {
		return DEFAULT_IMAGE_AVATAR;
	}
	try {
		const imageURL = await getDownloadURL(ref(storage, path));
		return imageURL;
	} catch (error) {
		return DEFAULT_IMAGE_AVATAR;
	}
}
