import { Box, Link, Typography } from "@mui/material";

export function Footer() {
	const linkStyle = {
		textDecoration: "none",
		userSelect: "text",
		color: "#fff",
		padding: "10px",
	};

	return (
		<Box
			display="flex"
			position="fixed"
			bottom="0"
			width="100%"
			justifyContent="space-between"
			alignItems="center"
			bgcolor="#000"
			color="primary"
			sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
			<Box color="white" width="100%" margin="15px" paddingX="10px">
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography variant="h6" component="h2">
						Contact Us
					</Typography>
					<Box padding="10px" sx={{ display: "flex", justifyContent: "space-around" }}>
						<Typography variant="body2" component="p">
							<Link sx={linkStyle} href="mailto:info@cvre.ca">
								Email: info@cvre.ca
							</Link>
						</Typography>
						<Typography variant="body2" component="p">
							<Link sx={linkStyle} href="callto:14165494915">
								Toll Free: 1 (416) - 549 - 4915
							</Link>
						</Typography>
						<Typography variant="body2" component="p">
							<Link sx={linkStyle} href="callto:18554865597">
								Toll Free: 1 (855) - 486 - 5597
							</Link>
						</Typography>
						<Typography variant="body2" component="p">
							<Link sx={linkStyle} href="callto:4165494916">
								Fax: (416) 549 - 4916
							</Link>
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
