import { createTheme } from "@mui/material";

export const appTheme = createTheme({
  typography: {
    fontFamily: "Tinos",
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 700,
    button: {
      fontFamily: "Lato",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    body1: {
      fontFamily: "Lato",
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {},
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#212121",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});
