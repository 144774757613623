import { ChangeEvent, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { collection, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { Dialog, DialogTitle, DialogContent, Box, Button, DialogActions, Alert } from "@mui/material";
import { db, upload, uploadFileToStorage } from "@/data/services";
import { FirebaseContext } from "@/contexts/firebase-context";

type PropsParam = {
  journeyId: string
  open: boolean
  onClose: Function
}

export function UploadFileModal(props: PropsParam) {
  const navigate = useNavigate()
  const location = useLocation()
  const {state: { user }} = useContext(FirebaseContext)
  const [title, setTitle] = useState('')
  const [file, setFile] = useState({} as File)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  function handleUploadFile(event: ChangeEvent<HTMLInputElement>) {
    const attach = event.target.files![0]
    setFile(attach)
    setTitle(attach.name)
  }

  function handleCleanFields() {
    setTitle('')
    setFile({} as File)
    setError(false)
    setLoading(false)
  }

  async function handleSubmit() {
    const fullPath = `storage_files/${user?.uid}/${props.journeyId}/${file.name}`
    const response = await getDocs(query(collection(db, 'storage_files'), where('fullPath', '==', fullPath)))

    if(response.docs.length == 0) {
      return setError(true)
    }
    setError(false)
    setLoading(true)

    const paths = await uploadFileToStorage(props.journeyId, `storage_files/${user?.uid}`, file.name, file)
    const data = { title, fileSize: file.size, updatedDate: serverTimestamp(), ...paths }
    upload(response.docs[0].id, 'storage_files', data).then(() => {
      setLoading(false)
      setSuccess(true)
      props.onClose()
      navigate(location.pathname + location.search + location.hash, { replace: true })
      handleCleanFields()
    })
  }

  return (
    <Dialog open={props.open} onClose={() => props.onClose()} >
      <DialogTitle>
        Would you like to upload a file?
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" flex="1" gap="20px" marginTop="20px">
          <Button variant="contained" component="label" fullWidth>
            {!file.name ? 'choose a file' : 'choose a file( '+ file?.name +' )'}
            <input hidden accept="*" type="file" onChange={handleUploadFile} />
          </Button>
          {error && <Alert severity="error">There are no files with this name to be replaced.</Alert>}
          {loading && <Alert severity="warning"> <b>Loading...</b> Please wait while the file is being sent to the server.</Alert>}
          {success && <Alert severity="success">Your document has been successfully sent.</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>CANCEL</Button>
        <Button onClick={handleSubmit} autoFocus>UPLOAD</Button>
      </DialogActions>
    </Dialog>
  )
}