import { Builder } from "../models/builder";
import { findById } from "./firebase-service";


export async function findBuilderById(suiteId: string) {
  const builderResponse = await findById(suiteId, "builders");
  const parserData = {
    ...builderResponse,
    createdDate: builderResponse.createdDate?.toDate(),
    updatedDate: builderResponse.updatedDate?.toDate(),
  }
  return parserData as Builder;
}