// Header.tsx
import React from "react";
import { Row, Col } from "reactstrap";
import bgImage from "@/components/widgets/img_cvre.jpg"; // Ensure the path is correct based on your project structure
import { Brightness1, Contrast } from "@mui/icons-material";

function Header() {
	const pageHeader = React.useRef(null);

	React.useEffect(() => {
		if (window.innerWidth > 991) {
			const updateScroll = () => {
				let windowScrollTop = window.pageYOffset / 3;
				// if (pageHeader.current) {
				//   pageHeader.current.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
				// }
			};
			window.addEventListener("scroll", updateScroll);
			return () => {
				window.removeEventListener("scroll", updateScroll);
			};
		}
	}, []);

	return (
		<>
			<div
				className="page-header page-header-small"
				style={{
					minHeight: "45vh",
					maxHeight: "999px",
					padding: 0,
					color: "#FFFFFF",
					position: "relative",
					overflow: "hidden",
					width: "100%",
					height: "100%",
					display: "block",
				}}>
				<div
					className="page-header-image"
					style={{
						backgroundImage: `url(${bgImage})`,
						backgroundSize: "cover",
						backgroundPosition: "center", // corrected to camelCase
						backgroundRepeat: "no-repeat", // corrected to camelCase and quotes added
						position: "absolute",
						width: "100%",
						height: "100%",
						left: 0,
						top: 0,
						opacity: 1, // removed quotes for numeric value
						filter: "brightness(0.5)",
					}}
					ref={pageHeader}></div>
				<div
					className="content-center"
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						zIndex: 2,
						transform: "translate(-50%, -50%)",
						textAlign: "center",
						color: "#FFFFFF",
						padding: "0 15px",
						width: "100%",
						maxWidth: "880px",
					}}>
					<Row>
						<Col className="ml-auto mr-auto" md="8">
							<h1
								className="title"
								style={{
									color: "#FFFFFF",
									fontSize: "2.4rem",
									lineHeight: "1.15",
									marginBottom: "30px",
									fontWeight: 1000,
									filter: "brightness(3.5)",
									textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
								}}>
								YOUR NEW DREAM HOME AWAITS
							</h1>
							<h4
								style={{
									fontSize: "1.0rem",
									lineHeight: "1.45em",
									marginTop: "30px",
									marginBottom: "15px",
									fontWeight: 600,
								}}>
								BUY NOW FROM YOUR NEW SALES DEPARTMENT <br />
								BUY ANYWHERE, ANYTIME
							</h4>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}

export default Header;
