import { createContext } from "react";
import { FirebaseApp } from "@firebase/app";
import { Auth, User } from "@firebase/auth";
import { Firestore } from "firebase/firestore";
import { FirebaseStorage } from "firebase/storage";

export type FirebaseData = {
  user: User | null;
  app: FirebaseApp;
  auth: Auth;
  db: Firestore;
  storage: FirebaseStorage;
}

export type FirebaseContext = {
  state: FirebaseData,
  setState: React.Dispatch<React.SetStateAction<FirebaseData>>
}

export const FirebaseContext = createContext<FirebaseContext>({} as FirebaseContext);