import { useState, useContext, Fragment } from "react";
import { AppBar, Toolbar, Typography, IconButton, MenuItem, Menu, Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle } from "@mui/icons-material";
import { FirebaseContext } from "@/contexts/firebase-context";
import { useNavigate } from "react-router-dom";
import logo from "./Condoville-App-Black.png";

type PropsParam = {
	toggleDrawer: () => void;
};

export function NavigationBar(props: PropsParam) {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const {
		state: { auth },
	} = useContext(FirebaseContext);

	function handleLoginOut() {
		auth.signOut().then(() => {
			navigate("/signin");
			window.location.reload();
		});
	}

	return (
		<Fragment>
			<AppBar
				sx={{
					display: { xs: "block", sm: "block", md: "block", lg: "block" },
					overflow: "hidden",

					backgroundColor: "#020202",
				}}>
				<Toolbar>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={props.toggleDrawer}
						sx={{ mr: 2, display: { sm: "none" } }}>
						<MenuIcon />
					</IconButton>
					<Box
						onClick={() => navigate("/")}
						component="a"
						sx={{
							flexGrow: 1,
							cursor: "pointer",
							display: "inline-block", // Ensures the box is inline with other elements
							textDecoration: "none", // Removes any default link styles
						}}>
						<img
							src={logo}
							alt="Condoville Real Estate"
							style={{ width: "min(100%, 350px)", height: "auto" }} // Adjust as needed
						/>
					</Box>

					{auth.currentUser ? (
						<>
							<Box sx={{ display: { xs: "none", sm: "block" } }}>
								<Button sx={{ color: "#fff" }} onClick={() => navigate("/listings")}>
									Listings
								</Button>
								<Button sx={{ color: "#fff" }} onClick={() => navigate("/watchlist")}>
									WatchList
								</Button>
								<Button sx={{ color: "#fff" }} onClick={() => navigate("/enquired-properties")}>
									Enquired Properties
								</Button>
								<Button sx={{ color: "#fff" }} onClick={() => navigate("/contact")}>
									Contact
								</Button>
							</Box>
							<IconButton
								size="large"
								aria-label=""
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={(event) => {
									setAnchorEl(event.currentTarget);
								}}
								color="inherit">
								<AccountCircle />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorEl)}
								onClose={() => setAnchorEl(null)}>
								<MenuItem onClick={() => navigate("/account")}>Account</MenuItem>
								<MenuItem onClick={handleLoginOut}>Sign-out</MenuItem>
							</Menu>
						</>
					) : (
						<Box
							sx={{
								display: { xs: "none", sm: "block" },
								paddingRight: "30px",
							}}>
							<Button sx={{ color: "#fff" }} onClick={() => navigate("/signin")}>
								Sign In
							</Button>
						</Box>
					)}
				</Toolbar>
			</AppBar>
			{/* this is a sadow relative element to stay under the fixed element */}
			<Box style={{ height: "4rem" }} sx={{ display: { xs: "block", sm: "block", md: "block", lg: "block" } }} />
		</Fragment>
	);
}
