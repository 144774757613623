import { Auth } from "@firebase/auth";
import { addDoc, collection, Firestore, serverTimestamp} from "firebase/firestore";

export function createAnalyticalLog(auth: Auth, db: Firestore, event: number, data?: {[key: string]: any}) {
  const newEvent = {
    event,
    customerID: auth.currentUser?.uid ?? null,
    data,
    timestamp: serverTimestamp(),
  };
  return addDoc(collection(db, "analytics"), newEvent);
};