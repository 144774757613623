import { useState } from "react";
import { Paper, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { AccountCircle, Home, Favorite, Call, AddHome } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom'

type PropsParam = any

export function BottomNavigationBar(props: PropsParam) {
  const navigate = useNavigate()
  const [selected, setSelected] = useState('HomeView')

  return (
    <Paper
      sx={{ 
        paddingRight: "2vw", paddingLeft: "2vw", position: "fixed", 
        bottom: 0, left: 0, right: 0, zIndex: "1000",
        display: { xs: "block", sm: "block", md: "none", lg: "none" },
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={selected}
        onChange={(event, value) => setSelected(value)}
      >
        <BottomNavigationAction
          sx={{ minWidth: "70px" }}
          label="Home"
          icon={<Home />}
          value="HomeView"
          onClick={() => navigate('/')}
        />
        <BottomNavigationAction
          sx={{ minWidth: "70px" }}
          label="Watchlist"
          icon={<Favorite />}
          value="WatchedView"
          onClick={() => navigate('/watchlist')}
        />
        {/* <BottomNavigationAction
          sx={{ minWidth: "70px" }}
          label="Drive"
          icon={< Folder />}
          value="SharedDriveView"
        /> */}
        <BottomNavigationAction
          sx={{ minWidth: "70px" }}
          label="Enquired Properties"
          icon={<AddHome />}
          value="EnquiredPropertiesView"
          onClick={() => navigate('/enquired-properties')}
        />
        <BottomNavigationAction
          sx={{ minWidth: "70px" }}
          label="Contact"
          icon={<Call />}
          value="ContactView"
          onClick={() => navigate('/contact')}
        />
        <BottomNavigationAction
          sx={{ minWidth: "70px" }}
          label="Account"
          icon={<AccountCircle />}
          value="AccountView"
          onClick={() => navigate('/account')}
        />
      </BottomNavigation>
    </Paper>
  );
};
