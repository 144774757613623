import { Box, ListItem, List, Drawer, ListItemButton, Divider, ListItemText, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "@/contexts/firebase-context";

type PropsParam = {
  drawerOpen: boolean;
  toggleDrawer: () => void;
}

type NavLink = {
  title: string;
  link: string;
}

export const NavigationDrawer = (props: PropsParam) => {
  const [navigationList, setNavigationList] = useState<NavLink[]>([])
  const { state } = useContext(FirebaseContext);
  const navigate = useNavigate()
  
  const NAVIGATION_LINKS = [{
    title: 'Home',
    link: '/'
  },{
    title: 'Listings',
    link: '/listings'
  },{
    title: 'Watchlist',
    link: '/watchlist'
  },{
    title: 'Enquired Properties',
    link: '/enquired-properties'
  },{
    title: 'Contact',
    link: '/contact'
  },{
    title: 'Account',
    link: '/account'
  }];

  useEffect(() => {
    if (state.user) {
      setNavigationList(NAVIGATION_LINKS);
    } else {
      setNavigationList([{
        title: 'Sign In',
        link: '/signin'
      }]);
    }
  }, [state.user]);

  return (
    <Box>
      <Drawer
        variant="temporary"
        open={props.drawerOpen}
        onClose={props.toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
          },
        }}
      >
        <Box onClick={props.toggleDrawer} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ my: 2 }}>
            CVRE
          </Typography>
          <Divider />
          <List>
            {navigationList.map((obj, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => navigate(obj.link)}>
                  <ListItemText primary={obj.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};
