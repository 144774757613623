import { Box } from "@mui/material";
import { Fragment } from "react";
import { useRouteError } from "react-router-dom";

type PropsParam = {
  title?: string
}

export function InvalidPathView(props: PropsParam) {
  const error = useRouteError() as {data: string};
  console.error(error);
  return (
    <Fragment>
      <Box>PAGE NOT FOUND!</Box>
      <Box>{error.data}</Box>
    </Fragment>
  )
};
