import { ObjectKey, Suite, SuiteGroup } from "../models"

function floorPremium(row: ObjectKey) {
  return Number((row.floor * row.suiteGroup.premium) - row.suiteGroup.premium)
}

function priceCalc(row: ObjectKey) {
  return Number((row.suiteGroup.totalSqFt * row.suiteGroup.priceSqFtFee) + floorPremium(row))
}

export function ffPrice(row: Suite & {suiteGroup: SuiteGroup}) {
  return Number((row.priceBump + 900 + (Math.round(priceCalc(row) / 1000) * 1000)))
}