import { ObjectKey } from "@/data/models";

/**
 * this function is responsible to send event to the react native app
 * 
 * @param {string} key Event Key example: LOGIN_ENV | LOGINOUT_EVENT
 * @param {ObjectKey} value data object to be send
 */
export function reactNativeEventEmitter(key: string, value: ObjectKey) {
  try {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ key, value }));
    }
  } catch (error) {
    console.log('Error to send message to react native', error);
  }
}

export function reactNativeEventListener(callback: Function) {
  window.addEventListener('message', (event) => {
    try {
      const message: ObjectKey = typeof event.data == 'string' ? JSON.parse(event.data) : event.data
      switch (message.key) {
        case 'GET_FCM_TOKEN':
          callback(message);
        break;
      }
    } catch (error) {
      console.log("error")
    }
  });
}