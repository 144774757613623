import { useContext, useEffect, useState } from "react";
import { Box, Avatar, Button, CssBaseline, TextField, Link, Paper, Grid, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { signInWithEmailAndPassword } from "firebase/auth";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "@/contexts/firebase-context";
import { reactNativeEventEmitter } from "@/data/services";
import { ArrowBackIos } from "@mui/icons-material";

type PropsParam = {
	title: string;
};

export function LoginView(props: PropsParam) {
	const navigate = useNavigate();
	const {
		state: { auth },
	} = useContext(FirebaseContext);

	const [error, setError] = useState(false);
	const [helperText, setHelperText] = useState("");

	function handleFormSubmit(e: React.FormEvent<HTMLFormElement>): void {
		e.preventDefault();
		const formFields = e.target as typeof e.target & {
			email: { value: string };
			password: { value: string };
		};
		if (formFields.email.value && formFields.password.value) {
			signInWithEmailAndPassword(auth, formFields.email.value, formFields.password.value)
				.then((userCredential) => {
					if (isMobile) {
						//TODO: Should be set to framework by detecting the login method...
						// console.log('mobile divice');
						reactNativeEventEmitter("GET_FCM_TOKEN", { value: userCredential.user.uid });
					}
					navigate("/");
				})
				.catch((err) => {
					setError(true);
					setHelperText(err.code);
				});
		} else {
			setError(true);
			setHelperText("Please enter a valid Password and Email address");
		}
	}

	useEffect(() => {
		document.title = props.title;
	}, []);

	return (
		<Box sx={{ backgroundColor: "white", width: "100vw" }}>
			<Grid container component="main" sx={{ height: "100vh" }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage:
							"url(https://condovillerealestate.ca/wp-content/uploads/2019/03/The-Frontenac-Aerial-Sketch-e1552316109512.jpg)",
						backgroundRepeat: "no-repeat",
						backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							display: "flex",
							pl: 4,
							height: 56,
							bgcolor: "#000",
							alignItems: "center",
						}}>
						<Typography
							onClick={() => navigate("/")}
							variant="h6"
							component="a"
							sx={{
								display: "flex",
								alignItems: "center",
								textTransform: "capitalize",
								cursor: "pointer",
								color: "#fff",
							}}>
							<ArrowBackIos sx={{ marginRight: "0.5rem" }} />
							Condoville Real Estate
						</Typography>
					</Box>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Avatar sx={{ m: 1 }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<Box component="form" noValidate onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								autoCapitalize="off"
								autoCorrect="off"
								error={error}
								helperText={helperText}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								autoCapitalize="off"
								autoCorrect="off"
								error={error}
								helperText={helperText}
							/>
							<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
								Sign In
							</Button>
							<Grid container>
								<Grid item xs>
									<Link onClick={() => navigate("/resetpassword")}>
										<Typography variant="body2">Forgot password?</Typography>
									</Link>
								</Grid>
								<Grid item>
									<Link onClick={() => navigate("/signup")}>
										<Typography variant="body2">Don&apos;t have an account? Sign Up</Typography>
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
