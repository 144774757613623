import "../assets/scss/app.scss";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  BrowserRouter as Router,
} from "react-router-dom";
import { RootLayout } from "./components/layouts/RootLayout";
import { appTheme } from "./Theme";
import { AccountView } from "./views/AccountView";
import { BuilderView } from "./views/BuilderView";
import { ContactView } from "./views/ContactView";
import { InvalidPathView } from "./views/InvalidPathView";
import { SuiteDetailsView } from "./views/SuiteDetailsView";
import { LoginView } from "./views/LoginView";
import { ResetPasswordView } from "./views/PasswordReset";
import { RegisterView } from "./views/RegisterView";
import { useState } from "react";
import { FirebaseContext, FirebaseData } from "./contexts/firebase-context";
import { builderLoader } from "./data/loaders/builder-loader";
import { EnquiredPropertiesView } from "./views/EnquiredPropertiesView";
import { AttachFileView } from "./views/AttachFileView";
import { attachmentsLoader } from "./data/loaders/attachments-loader";
import { enquiredPropertiesLoader } from "./data/loaders/enquired-properties-loader";
import { HomeView } from "./views/HomeView";
import { SuitesView } from "./views/SuitesView";
import { ProjectsView } from "./views/ProjectsView";
import { WatchedView } from "./views/WatchedView";
// import { QueryView } from "./views/QueryView"; 


export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<InvalidPathView />}>
      <Route element={<RootLayout />} >
        <Route
          index
          element={<HomeView title="Condoville - Home" />}
        />
        <Route
          path="/suites/:id"
          element={<SuitesView title="Condoville - Suites" />}
        />
        <Route
          path="/suites/:suiteGroupId/details/:id"
          element={<SuiteDetailsView title="Condoville - Details" />}
        />
        <Route
          path="/listings"
          element={<HomeView title="Condoville - Listings" />}
        />
        <Route
          path="/signin"
          element={<LoginView title="Condoville - Sign in" />}
        />
        <Route
          path="/signup"
          element={<RegisterView title="Condoville - Create account" />}
        />
        <Route
          path="/resetpassword"
          element={<ResetPasswordView title="Condoville - Reset password" />}
        />
      </Route>
      <Route element={<RootLayout protected />}>
        <Route
          path="/builders/:id"
          loader={builderLoader}
          element={<BuilderView title="Condoville - Builders" />}
        />
        <Route
          path="/builders/:id/previous-projects"
          element={<ProjectsView current={false} title="Condoville - Previous Projects" />}
        />
        <Route
          path="/builders/:id/current-projects"
          element={ <ProjectsView current title="Condoville - Current Projects" />}
        />
        <Route
          path="/enquired-properties"
          loader={enquiredPropertiesLoader}
          element={
            <EnquiredPropertiesView title="Condoville - Enquired Properties" />
          }
        />
        <Route
          path="/enquired-properties/:id"
          loader={attachmentsLoader}
          element={<AttachFileView title="Condoville - Attachments Files" />}
        />
        <Route
          path="/account"
          element={<AccountView title="Condoville - Account" />}
        />
        <Route
          path="/watchlist"
          element={<WatchedView title="Condoville - WatchList" />}
        />
        <Route
          path="/contact"
          element={<ContactView title="Condoville - Contact CVRE" />}
        />
        {/* <Route path="/query" element={<QueryView/>} /> New query route */}
      </Route>
    </Route>
  )
);

export function RootContainer(props: { firebase: FirebaseData }) {
  const [state, setState] = useState(props.firebase);
  return (
    <FirebaseContext.Provider value={{ state, setState }}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </FirebaseContext.Provider>
  );
}
