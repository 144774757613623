//@ts-ignore
import * as PDFJS from 'pdfjs-dist/webpack';

//checks if URL is a PDF
export async function isPdfUrl(url: any) {
    const ext = url.split('.').pop().toLowerCase();
    return ext.split('?')[0] === 'pdf';
}

// generating PDF preview
export async function generatePDFpreview(urlPdf: string) {
    const pdf = await PDFJS.getDocument(urlPdf).promise;
    const page = await pdf.getPage(1);
    const scale = 1.5;
    const viewport = page.getViewport({ scale: scale });
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    const url = canvas.toDataURL();
    return url;
}