import { Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import { Fragment, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, Unsubscribe } from 'firebase/auth';
import { auth, findCustomerById, reactNativeEventEmitter, reactNativeEventListener, updateCustomerById } from '@/data/services';
import { FirebaseContext } from '@/contexts/firebase-context';
import { Footer, NavigationBar, BottomNavigationBar, NavigationDrawer } from '@/components/widgets';
import { ObjectKey } from '@/data/models';


type PropsParam = {
  protected?: boolean;
};

export function RootLayout(props: PropsParam) {
  const nativate = useNavigate();
  const location = useLocation();
  const { state, setState } = useContext(FirebaseContext);
  const [drawerOpen, setDrawer] = useState<boolean>(false);
  const [firstLoaderPage, setFirstLoaderPage] = useState(true);

  reactNativeEventListener(async (messageData: ObjectKey) => {
    switch (messageData.key) {
      case 'GET_FCM_TOKEN':
        if (state.user) {
          const customer = await findCustomerById(state.user.uid);
          const devices = {
            android: customer.devices?.android || [],
            ios: customer.devices?.ios || []
          }
          if (messageData.value.platform == 'ios') {
            devices.ios.push(messageData.value.token);
          } else {
            devices.android.push(messageData.value.token);
          }
          const data = {
            devices: {
              android: [...new Set(devices.android)],
              ios: [...new Set(devices.ios)],
            }
          }
          reactNativeEventEmitter('GET_FCM_TOKEN_RESPONSE', { value: data.devices });
          await updateCustomerById(state.user.uid, data);

        }
      break;
    }
  })

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (firstLoaderPage) {
      setFirstLoaderPage(false)
      // update context user login and logout
      unsubscribe = onAuthStateChanged(auth, (user) => {
        setState((old) => ({ ...old, user }));
        if (user) {
          localStorage.setItem('userID', user.uid);
          reactNativeEventEmitter('GET_FCM_TOKEN', { value: user.uid });
        }
        if (props.protected && !user) {
          localStorage.removeItem('userID');
          nativate('/signin');
        }
      });
    }
    return () => unsubscribe();
  }, [state.user]);

  return (
    <Fragment>
      {!['/signin', '/signup', '/resetpassword'].includes(location.pathname) && (
        <Fragment>
          <NavigationBar 
            toggleDrawer={() => setDrawer((old) => !old)}
          />
          <NavigationDrawer         
            drawerOpen={drawerOpen}
            toggleDrawer={() => setDrawer((old) => !old)}
          />
          {/* show navigation just if the user is logged in */}
          {state.user && <BottomNavigationBar />}
        </Fragment>
      )}
      <Box width='full' minHeight='100vh'>
        <Outlet />
      </Box>
      {!['/signin', '/signup', '/resetpassword'].includes(location.pathname) && (
        <Fragment>
          <Box height='5rem' />
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
}
