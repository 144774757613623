import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FirebaseContext } from "@/contexts/firebase-context";
import {
	IconButton,
	Typography,
	Card,
	CardMedia,
	Box,
	Button,
	CardActions,
	CircularProgress,
	Container,
	Grid,
	CardContent,
} from "@mui/material";
import { Favorite, BedroomParentOutlined, BathroomOutlined } from "@mui/icons-material";
import { SuiteGroup } from "@/data/models/suite-group";
import {
	findAllBuilding,
	findAllCustomerGroupById,
	findAllSuiteGroupsByCustomerGroup,
	findAllWatchedListingsCode,
	findById,
	findPublicGroup,
	updateWatchedListingsCode,
} from "@/data/services";
import { Customer, CustomerGroup } from "@/data/models";
import { ScrollToTopButton } from "@/components/widgets/ScrollToTopButton";
import FilterBar from "@/components/widgets/FilterBar";
import { isPdfUrl, generatePDFpreview } from "@/components/tools";
import Header from "@/views/Header";

type PropsParam = {
	title: string;
};

export function HomeView({ title }: PropsParam) {
	const {
		state: { user },
	} = useContext(FirebaseContext);
	const [suiteGroups, setSuiteGroups] = useState<Array<SuiteGroup>>([]); // Stores every single suite group from db
	const [filteredSuiteGroups, setFilteredSuiteGroups] = useState<Array<SuiteGroup>>([]);
	const [currentVisibleSuiteIndex, setCurrentVisibleSuiteIndex] = useState<number>(0); // lock number of suites visible to fake pagination
	const [showLoading, setLoading] = useState<boolean>(true); // controls loading indicator
	const [watchList, setWatchList] = useState<Array<string>>([]);
	const [firstLoaderPage, setFirstLoaderPage] = useState(true);
	const [isEndOfPage, setIsEndOfPage] = useState(false); // controls loading indicator
	const [buildingHashMap, setBuildingHashMap] = useState<Record<string, any>>({});
	const [loggedUser, setLoggedUser] = useState(false);
	const [customerGroup, setCustomerGroup] = useState<Array<CustomerGroup>>([]);

	const location = useLocation();

	const MAX_SUITE_DISPLAY = 12;

	async function handleFindAllSuiteGroups() {
		try {
			const publicCustomerGroup = await findPublicGroup();
			const searchOnCustomerGroups: Array<CustomerGroup> = [publicCustomerGroup];
			if (user) {
				const customer = await findById<Customer>(user.uid, "customers");
				if (customer) {
					const customerGroups = await findAllCustomerGroupById(customer.customerGroup);
					searchOnCustomerGroups.push(...customerGroups);
					setCustomerGroup(searchOnCustomerGroups);
				}
			}
			const response = await findAllSuiteGroupsByCustomerGroup(searchOnCustomerGroups);

			setSuiteGroups((prevSuiteGroups) => {
				const newSuiteGroups = response.suiteGroups.map(async (suiteGroup) => {
					if (suiteGroup.imageURL && (await isPdfUrl(suiteGroup.imageURL))) {
						const previewURL = await generatePDFpreview(suiteGroup.imageURL);
						// console.log("ispdf", suiteGroup);
						return { ...suiteGroup, imageURL: previewURL };
					} else {
						return suiteGroup;
					}
				});

				Promise.all(newSuiteGroups)
					.then((updatedSuiteGroups) => {
						const updatedGroups = [...prevSuiteGroups, ...updatedSuiteGroups];
						setSuiteGroups(updatedGroups);
						setFilteredSuiteGroups(updatedGroups);
						setCurrentVisibleSuiteIndex(Math.min(updatedGroups.length, MAX_SUITE_DISPLAY));
						setLoading(false);
					})
					.catch((error) => {
						console.error("Error generating PDF previews:", error);
						setLoading(false);
					});

				return prevSuiteGroups;
			});
		} catch (error) {
			console.error("Error fetching suite groups:", error);
			setLoading(false);
		}
	}

	async function handleAddToWatchList(groupId: string) {
		if (watchList.includes(groupId)) {
			updateWatchedListingsCode(user?.uid!, groupId, true);
			setWatchList((previous) => previous.filter((heart) => heart !== groupId));
		} else {
			updateWatchedListingsCode(user?.uid!, groupId, false);
			setWatchList([...watchList, groupId]);
		}
	}

	async function handleBuildingHashMap() {
		try {
			const buildings = await findAllBuilding();
			const createBuildingHashMap: any = {};
			for (const building of buildings) {
				createBuildingHashMap[building.id!] = building;
			}
			setBuildingHashMap(createBuildingHashMap);
			// console.log("Building HashMap:", createBuildingHashMap); // Debug log
		} catch (error) {
			console.error("Error fetching buildings:", error);
		}
	}

	const filterSuites = (
		suites: SuiteGroup[],
		filters: {
			buildingName: string;
			city: string;
			priceRange: string;
			bedrooms: string[];
			bathrooms: string[];
		}
	) => {
		// console.log("Filters Applied:", filters); // Debug log
		return suites.filter((suite) => {
			// Log the suite data for debugging
			// console.log("Suite Data:", suite);
			const buildingNameMatches = filters.buildingName
				? buildingHashMap[suite.buildingID].buildingName === filters.buildingName
				: true;
			const cityMatches = filters.city ? buildingHashMap[suite.buildingID].city === filters.city : true;
			const priceRangeMatches = (() => {
				const price = suite.totalSqFt * suite.priceSqFtFee;
				if (!filters.priceRange) {
					return true;
				} else if (filters.priceRange === "300000-400000") {
					return price >= 300000 && price < 400000;
				} else if (filters.priceRange === "400000-500000") {
					return price >= 400000 && price < 500000;
				} else if (filters.priceRange === "500000-600000") {
					return price >= 500000 && price < 600000;
				} else if (filters.priceRange === "600000-700000") {
					return price >= 600000 && price < 700000;
				} else if (filters.priceRange === "700000 or more") {
					return price >= 700000;
				} else {
					return true; // Defaults to true if no price range filter is set
				}
			})();
			const bedroomsMatches =
				filters.bedrooms.length === 0 ||
				filters.bedrooms.some((filterBedroom: string) => {
					return (
						filterBedroom === String(suite.bedroomCount) ||
						(filterBedroom.endsWith("+") &&
							Number(String(suite.bedroomCount).replace("+", "")) >=
								Number(filterBedroom.replace("+", "")))
					);
				});
			const bathroomsMatches =
				filters.bathrooms.length === 0 ||
				filters.bathrooms.some((filterBathroom: string) => {
					return (
						filterBathroom === String(suite.bathroomCount) ||
						(filterBathroom.endsWith("+") &&
							Number(suite.bathroomCount) >= Number(filterBathroom.replace("+", "")))
					);
				});

			setCurrentVisibleSuiteIndex(MAX_SUITE_DISPLAY);
			return buildingNameMatches && cityMatches && priceRangeMatches && bedroomsMatches && bathroomsMatches;
		});
	};

	useEffect(() => {
		if (firstLoaderPage) {
			document.title = title;
			setFirstLoaderPage(false);
			handleBuildingHashMap();
			handleFindAllSuiteGroups();
		}
		if (user) {
			setSuiteGroups([]);
			setTimeout(() => setLoggedUser(true), 100);
		}
	}, [user]);

	useEffect(() => {
		if (loggedUser) {
			if (user) {
				handleFindAllSuiteGroups();
				findAllWatchedListingsCode(user.uid).then((response) => setWatchList(response));
			}
		}
	}, [loggedUser]);

	useEffect(() => {
		function handleScroll() {
			if (currentVisibleSuiteIndex >= filteredSuiteGroups.length) {
				return;
			}
			const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
			const body = document.body;
			const html = document.documentElement;
			const documentHeight = Math.max(
				body.scrollHeight,
				body.offsetHeight,
				html.clientHeight,
				html.scrollHeight,
				html.offsetHeight
			);
			const windowBottom = windowHeight + window.pageYOffset;
			if (windowBottom >= documentHeight - 10) {
				setIsEndOfPage(true);
			} else {
				setIsEndOfPage(false);
			}
		}

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [filteredSuiteGroups, currentVisibleSuiteIndex]);

	useEffect(() => {
		if (isEndOfPage) {
			setLoading(true);
			// create fake loading effect
			setTimeout(() => {
				setLoading(false);
				setCurrentVisibleSuiteIndex((prev) => {
					return prev + MAX_SUITE_DISPLAY;
				});
			}, 200 + Math.random() * 600);
		}
	}, [isEndOfPage]);

	useEffect(() => {
		const filters = getQueryParams();
		// console.log("Query Parameters:", filters); // Debug log

		if (suiteGroups.length > 0) {
			// console.log("Suite Groups:", suiteGroups); // Log to check the data
			const filteredSuites = filterSuites(suiteGroups, filters);
			// console.log("Filtered Suites:", filteredSuites); // Log to check the filtered data
			setFilteredSuiteGroups(filteredSuites);
		} else {
			console.warn("No suite groups available for filtering.");
		}
	}, [location.search, suiteGroups]);

	const getQueryParams = () => {
		const params = new URLSearchParams(location.search);
		return {
			buildingName: params.get("building_name") || "",
			city: params.get("city") || "",
			priceRange: params.get("price_range") || "",
			bedrooms: params.get("bedrooms") ? params.get("bedrooms")!.split(",") : [],
			bathrooms: params.get("bathrooms") ? params.get("bathrooms")!.split(",") : [],
		};
	};

	return (
		<div
			style={{
				backgroundColor: "#ffffff",
				color: "#000000",
			}}>
			<Header />
			<Box display="flex">
				<ScrollToTopButton />
				<Container maxWidth="lg">
					<FilterBar customerGroup={customerGroup} />
					<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
						{filteredSuiteGroups &&
							filteredSuiteGroups.length > 0 &&
							filteredSuiteGroups.slice(0, currentVisibleSuiteIndex).map((suiteGroup, index) => (
								<Grid key={`suitegroup_index_${suiteGroup.id}_${index}`} xs={12} sm={4} md={3} item>
									<Card sx={{ minHeight: "400px", display: "flex", flexDirection: "column" }}>
										<CardMedia
											component="img"
											width="100%"
											height="200"
											image={suiteGroup.imageURL}
										/>
										<CardContent>
											<Typography
												gutterBottom
												variant="h6"
												component="div"
												textOverflow={"ellipsis"}
												overflow={"hidden"}
												whiteSpace={"nowrap"}>
												{suiteGroup?.suiteGroupType ?? "n/a"}
											</Typography>
											<Typography gutterBottom variant="body2" color="text.secondary">
												{`${buildingHashMap[suiteGroup?.buildingID]?.streetAddress ?? "n/a"}, ${
													buildingHashMap[suiteGroup?.buildingID]?.city ?? ""
												}`}
											</Typography>
											{!!suiteGroup?.balconySqFt ? (
												<Typography
													variant="body2"
													color="text.secondary"
													textOverflow={"ellipsis"}
													overflow={"hidden"}
													whiteSpace={"nowrap"}>
													{`Indoor ${suiteGroup.balconySqFt} sq. ft. / Outdoor ${
														Number(suiteGroup?.totalSqFt) -
															Number(suiteGroup.balconySqFt) ?? "n/a"
													} sq. ft.`}
												</Typography>
											) : null}
											<Typography gutterBottom variant="body2" color="text.secondary">
												{`Total ${suiteGroup?.totalSqFt || "n/a"} sq. ft.`}
											</Typography>
											<Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
												<BedroomParentOutlined />
												<Typography variant="body2" color="text.secondary">
													{suiteGroup?.bedroomCount ?? "n/a"} Bedrooms
												</Typography>
											</Box>
											<Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
												<BathroomOutlined />
												<Typography variant="body2" color="text.secondary">
													{suiteGroup?.bathroomCount ?? "n/a"} Bathrooms
												</Typography>
											</Box>
										</CardContent>
										<CardActions
											style={{
												paddingLeft: user ? "0" : "16px",
												marginTop: "auto",
												marginBottom: 16,
											}}>
											{user && (
												<IconButton
													sx={{
														color: watchList?.includes(suiteGroup.id) ? "red" : "primary",
													}}
													onClick={() => handleAddToWatchList(suiteGroup.id)}>
													<Favorite />
												</IconButton>
											)}
											<Button
												component={Link}
												to={"/suites/" + suiteGroup.id}
												size="small"
												color="primary"
												variant="contained">
												View
											</Button>
										</CardActions>
									</Card>
								</Grid>
							))}
					</Grid>
					{showLoading && (
						<Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
							<CircularProgress />
						</Box>
					)}
					{!showLoading && !filteredSuiteGroups.length && (
						<Typography align="center" sx={{ fontSize: "2.0rem", paddingY: "50px" }}>
							No suites found...
						</Typography>
					)}
				</Container>
			</Box>
		</div>
	);
}

export default HomeView;
