import { updateDoc, doc, arrayRemove, arrayUnion } from "firebase/firestore";
import { ObjectKey } from "../models";
import { Customer } from "../models/customer";
import { createAnalyticalLog } from "./analytics-service";
import { auth, db, findById } from "./firebase-service";


export async function findCustomerById(customerId: string) {
  return await findById<Customer>(customerId, "customers");
}

export async function findAllWatchedListingsCode(customerId: string): Promise<Array<string>> {
  const response = await findById<Customer>(customerId, "customers");
  return response ? response.watchedListings || [] : []
}

export async function updateCustomerById(customerId: string, data: ObjectKey): Promise<void> {
  const documentReference = doc(db, "customers", customerId);
  await updateDoc(documentReference, data);
}

export async function updateWatchedListingsCode(customerId: string, watchlistId: string, remove: boolean = false): Promise<boolean> {
  try {
    if (remove) {
      //suite_removed_watchlist
      createAnalyticalLog(auth, db, 7, { suiteID: watchlistId });
    } else {
      //suite_added_watchlist
      createAnalyticalLog(auth, db, 6, { suiteID: watchlistId });
    }
    await updateDoc(doc(db, "customers", customerId), {
      watchedListings: remove ? arrayRemove(watchlistId) : arrayUnion(watchlistId),
    });
    return true;
  } catch (error) {
    return false;
  }
}