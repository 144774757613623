import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getFirestore, serverTimestamp, updateDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebaseConfig from "../configs/firebase-config";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// find a documment with id and name of the collection
export async function findById<T = any>(id: string, collectionName: string): Promise<T> {
  const docSnap = await getDoc(doc(db, collectionName, id));
  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() } as T;
  }
  return null as T;
}

// save data on firebase
export async function save(collectionName: string, data: any) {
  data.createdDate = serverTimestamp()
  const referenceCollection = collection(db, collectionName);
  return await addDoc(referenceCollection, data);
}

// upload data
export async function upload(id: string, collectionName: string, data: any) {
  const documentReference = doc(db, collectionName, id);
  return await updateDoc(documentReference, data);
}