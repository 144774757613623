import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase-service";

/**
 * this function will upload a file to firebase storage
 * @param {string} id - it will be used to create a reference folder 
 * @param {string} folderName - it will be the name of the main folder
 * @param {string} fileName - name of the file and extension
 * @param {object} file - file to be upload
 * @returns {Promise} - { url: 'value', fullPath: 'value' } 
 */
export async function uploadFileToStorage(id: string, folderName: string, fileName: string, file: any) {
  const fileReference = ref(storage, folderName + '/' + id + '/' + fileName);
  await uploadBytes(fileReference, file);
  return Promise.resolve({ url: await getDownloadURL(fileReference), fullPath: fileReference.fullPath });
}

